import axiosMainProductGet, { allProd } from "../../APIs/AxiosMainProductGet";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import imgIcon from "../../Assets/imageIcon.svg";
import Icon from "@mdi/react";
import { mdilImage } from '@mdi/light-js';
import { blue } from "@mui/material/colors";
import { json } from "react-router-dom";





function ProductListImgControl (props){
    const IPMainProductsGet= process.env.REACT_APP_APIMAINPRDGET2_URL;

    const [products, setProducts] = useState([]);
    const [viewOne, setViewOne] =useState(0);
    const [key1, setKey1]= useState("");
    const [key2, setKey2]= useState("");
    
    

     
    const allprodAxios=()=>{
      axiosMainProductGet(IPMainProductsGet);
    }

    const selectedProd =(e) =>{
      console.log("clicked value: "+e.codigo);
      localStorage.setItem('prd', JSON.stringify(e));
      props.checki.push("a");
      window.location.reload(false);

    }
    
    
     


    useEffect(() => {
      let mounted = true;

      
      

      axios.get(IPMainProductsGet).then(res => {
        if(mounted) {
          
       setProducts(res.data);
      
       
       console.log("data montada "+res.data.length);
        }
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          }) 
          return () => mounted = false;

      
        
      
    }, [props.filter1,props.data1]); 

 
 
     


 




    


const prodss = () =>{
  
  let countProd=0;
  let imgRandomCount=0;
  let backgroundC="#F2EBE3";
  let fontColor="#050203";
  

        if(!products[0]||!products[1]){
          
         {axiosMainProductGet(IPMainProductsGet); console.log("???");}  

        }else{

       
        return(

          products.map((prod)=>{
            imgRandomCount++;
            if(prod.imgCount>0){
              backgroundC="#F2EBE3";
              fontColor="#050203";

            }else{ 
              backgroundC="#050203";
              fontColor="#F2EBE3";}

            if (prod.codigo<1){}else{
               
  
                return(
                      <tr value={prod} onClick={(e)=>selectedProd(prod)} style={{backgroundColor:`${backgroundC}`}}>
                        <td style={{color:`${fontColor}`}}>{prod.codigo}</td>
                        <td style={{color:`${fontColor}`}}>{prod.descripcion}</td>
                        <td style={{color:`${fontColor}`}}>{"RD$"+prod.solicitar_precio}</td>
                        <td style={{color:`${fontColor}`}}>{prod.imgCount}</td>
                        
                                  
                      </tr>
                      )

                    }
                  
              
                  
          })
          )


        
      
      }

      }

    

    return(<>
       
            
              {prodss()}
                
        

        
<ToastContainer/>



        </>
    )
}

export default ProductListImgControl;