import { Link } from "react-router-dom";



function FooterOne (){



    return(<>
        <div className="panel-header" style={{marginLeft:`2%`}} onClick={()=>{ window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });}}><i class="fa-solid fa-caret-up"></i> <i class="fa-solid fa-caret-up"></i> Regresar arriba<i class="fa-solid fa-caret-up"></i> <i class="fa-solid fa-caret-up"></i></div>
        <div className="hero desk" style={{backgroundColor:`#0085c9`}}>
            
            <br/>
            <div className="grid " style={{color:`white`}}>
                <div className="cell">
                    
                        
                        <ul >
                            <li className="h6">MUNDO BARATO</li>
                            <li><Link to={`/quienesSomos`} style={{color:`white`}}>Quiénes somos</Link></li>                            
                            <li><Link to={`/politicas`} style={{color:`white`}}>Politicas</Link></li>
                        </ul>
                      
                       
                    
                </div>
              
                <div className="cell"> 
                        <ul>
                            <li className="h6">CONTACTO</li>
                      
                            <li><a  style={{color:`white`}} href={`https://wa.me/18496544656?text=Buen%20día%20deseo%20más%20información%20sobre%20emprender%20con%20ustedes.`}>Emprende con nosotros</a></li>
                            <li >info/vacantes: telovendobaratord@gmail.com</li>
                           
                        </ul></div>
                <div className="cell" > 
                        <ul>
                            <li className="h6">UBÍCANOS</li>
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=18.479224,-69.970814&z=14&t=m&hl=en&gl=VE&mapclient=embed&cid=6642512338973017927">Santo Domingo</a></li>
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=18.486697,-69.832318&z=16&t=m&hl=en&gl=VE&mapclient=embed&cid=1966642127754851875">Coral Mall</a></li>
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=19.455647,-70.703366&z=12&t=m&hl=en&gl=VE&mapclient=embed&cid=1650515666294379627">Santiago</a></li>
                        </ul></div>
                
            </div>        
        </div>


        <div className="hero mob" style={{backgroundColor:`#0085c9`}}>
            
            <br/>
            <div className="grid " style={{color:`white`}}>
               
                    
                        
                        <ul >
                            <li className="h6">MUNDO BARATO</li>
                            <li><Link to={`/quienesSomos`} style={{color:`white`}}>Quiénes somos</Link></li>                            
                            <li><Link to={`/politicas`} style={{color:`white`}}>Politicas</Link></li>
                                          
                         
                            <li><a  style={{color:`white`}} href={`https://wa.me/18496544656?text=Buen%20día%20deseo%20más%20información%20sobre%20emprender%20con%20ustedes.`}>Emprende con nosotros</a></li>
                            <li >info/vacantes: telovendobaratord@gmail.com</li>
                                        
                          
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=18.479224,-69.970814&z=14&t=m&hl=en&gl=VE&mapclient=embed&cid=6642512338973017927">Santo Domingo</a></li>
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=18.486697,-69.832318&z=16&t=m&hl=en&gl=VE&mapclient=embed&cid=1966642127754851875">Coral Mall</a></li>
                            <li><a style={{color:`white`}} href="https://maps.google.com/maps?ll=19.455647,-70.703366&z=12&t=m&hl=en&gl=VE&mapclient=embed&cid=1650515666294379627">Santiago</a></li>
                        </ul>
                
            </div>        
        </div>
        </>
    )
}

export default FooterOne;