import axios from "axios";

export let imgExactGeti = [];

function AxiosImgExactGet (a){

  console.log("insideAxiosImgexactget")

 

    axios.get(a).then(res => {
        imgExactGeti=res.data;
        imgExactGeti=imgExactGeti.length;
        localStorage.setItem('imgx', JSON.stringify(imgExactGeti));
          
            
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          })   
       

}

export default AxiosImgExactGet;