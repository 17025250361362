import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { imgCountArr } from "../FunctionalComponents/ControlComponents/ImgUploader";
//import { imgCountArr } from "../Components/FuncCompnonents/ImgUpload";
//import { imgCountsArr} from "../Components/FuncCompnonents/ImgUploadS";
//import { formEnviado } from "./Axxios";
//import { item } from "../Views/SupSucursales";

export let sumImg=0;
let imgPostCount="";


function AxiosPatchImgControl (a, b){
  
  sumImg= imgCountArr;
 
 
  
 
    axios.patch(a, b,  {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        imgPostCount++;
       
        
        if((sumImg)===imgPostCount){
          
          toast.update("b", { render: `Artículo actualizado`, type: "success", isLoading: false });
          setTimeout(function() {
            toast.dismiss("b");
          
            
            
         },3200);
        }
       
            
           
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
                toast(`Error de conexión`, {
                    draggablePercent: 20, type: "error", autoClose: 10000, });
            }else {

            toast(`Error: ${error.message}`, {
                draggablePercent: 20, type: "error", autoClose: 10000, });}
          })   
        return(
            <ToastContainer draggablePercent={20}   limit={1} className="toast-position"/>

        );
   

}

export default AxiosPatchImgControl;