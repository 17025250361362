import { useState } from "react";
import imgprdi from "../Assets/MB1.png"



function CartProdRender (props){
    const [productValue, setProductValue] = useState([]);

    let cant=["1"];
  
    if(!props.cart[0]){
            
      return(
        <div className="label">Carrito vacío...</div>)
  
    }else{
  
  
      return(
  
    props.cart.map((prod)=>{
      cant[prod.codigo]=1;
      let a = prod.solicitar_precio;
      let h=[];
      let imgprdu;
      

      props.setTotalAmount(props.totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)));
        //console.log("inside prodMap: "+prod.codigo);
      if(!prod.cantidad){
        Object.assign(prod, {cantidad:1})
        //Object.defineProperty(prod, "cantidad", {value:"1"});
        h = prod.solicitar_precio;
      } else{
        h = prod.solicitar_precio*prod.cantidad;
      }

      if(!prod.imagen||prod.imagen===process.env.REACT_APP_NOMEDIA){
        //if(!currentPost.imagen||currentPost.imagen==="https://postgre-mbrd.onrender.com/media/%20"){
          imgprdu=imgprdi;
        } else {
          imgprdu=prod.imagen;

        }
  
       /*
        if(!props.cantidadi[prod.codigo]){
         
        }else{
          h = parseFloat(a)*props.cantidadi[prod.codigo];
        }
        <input className="input is-light" type="number" placeholder="1" value={props.cantidadi[prod.codigo]} onChange={(e)=>{props.cantidadi.splice(prod.codigo,0,e.target.value); h=e.target.value*a;  props.totalAmount[prod.codigo]=h; console.log(props.totalAmount)}}/>  
        
        */
        
        props.arrayfill.push(prod.descripcion);
        
        a=prod.solicitar_precio;
        if(!props.totalAmount){}else{
        props.totalAmount[prod.codigo]=h;}
        props.arrayfill2[prod.codigo]=">>>>>>"+"["+"Codigo: "+prod.codigo+","+"Nombre: "+prod.descripcion+","+"Precio: "+a+","+"cantidad"+prod.cantidad+"]"+" ";
          return( 
            <div class="card mb-3 mb-lg-0">
                    <div class="card-body " >
                      <div class="level">
                        <div class="level-left">
                          <div>
                            <img
                              src={imgprdu}
                              class="img-fluid rounded-3" alt="Shopping item" style={{width: `65px`}}/>
                          </div>
                          <div class="ms-3">
                            <p>{prod.descripcion}</p>
                            <p class="small mb-0">{prod.marca}</p>
                          </div>
                        </div> 
                        <div>
                          
                          
                          <p class="control level-item" key={props.cart}>
                          <span class="icon is-small is-left" onClick={(e)=>{if(e.target.value<0){props.CartProdDeleteItem(prod.codigo)}else{prod.cantidad=prod.cantidad-1}; 
                            h=prod.cantidad*a;props.productPrice[prod.codigo]=prod.cantidad*a;
                            const newArray = [...productValue]; newArray[prod.codigo] = prod.cantidad*a;setProductValue(newArray);  
                            const newArrayTotal = [...props.totalAmount]; newArrayTotal[prod.codigo] =h;props.setTotalAmount(props.totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)));  
                              console.log("Totalamount: "+props.totalAmount); 
                            
                              console.log("Current amount: "+props.productPrice[prod.codigo]);console.log("cantidad: "+prod.cantidad);
                              props.arrayfill2[prod.codigo]=">>>>>>"+"["+"Codigo: "+prod.codigo+","+"Nombre: "+prod.descripcion+","+"Precio: "+a+","+"cantidad"+prod.cantidad+"]"+" ";
                              console.log("Arrayfill 2?: "+props.arrayfill2); 
                              }}><i class="fa fa-minus" aria-hidden="true"></i></span>
                          <input className="input is-light " type="number" value={prod.cantidad}  onChange={(e)=>{if(e.target.value<0){props.CartProdDeleteItem(prod.codigo)}else{prod.cantidad=e.target.value}; 
                            h=e.target.value*a;props.productPrice[prod.codigo]=e.target.value*a;
                            const newArray = [...productValue]; newArray[prod.codigo] = e.target.value*a;setProductValue(newArray);  
                            const newArrayTotal = [...props.totalAmount]; newArrayTotal[prod.codigo] =h;props.setTotalAmount(props.totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)));  
                              console.log("Totalamount: "+props.totalAmount); 
                            
                              console.log("Current amount: "+props.productPrice[prod.codigo]);console.log("cantidad: "+prod.cantidad);
                              props.arrayfill2[prod.codigo]=">>>>>>"+"["+"Codigo: "+prod.codigo+","+"Nombre: "+prod.descripcion+","+"Precio: "+a+","+"cantidad"+prod.cantidad+"]"+" ";
                              console.log("Arrayfill 2?: "+props.arrayfill2); 
                              }}/>
                            <span class="icon is-small is-right" value={prod.cantidad} onClick={(e)=>{if(e.target.value<0){props.CartProdDeleteItem(prod.codigo)}else{prod.cantidad=prod.cantidad+1}; 
                            h=prod.cantidad*a;props.productPrice[prod.codigo]=prod.cantidad*a;
                            const newArray = [...productValue]; newArray[prod.codigo] = prod.cantidad*a;setProductValue(newArray);  
                            const newArrayTotal = [...props.totalAmount]; newArrayTotal[prod.codigo] =h;props.setTotalAmount(props.totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)));  
                              console.log("Totalamount: "+props.totalAmount); 
                            
                              console.log("Current amount: "+props.productPrice[prod.codigo]);console.log("cantidad: "+prod.cantidad);
                              props.arrayfill2[prod.codigo]=">>>>>>"+"["+"Codigo: "+prod.codigo+","+"Nombre: "+prod.descripcion+","+"Precio: "+a+","+"cantidad"+prod.cantidad+"]"+" ";
                              console.log("Arrayfill 2?: "+props.arrayfill2); 
                              }}> <i class="fa fa-plus" aria-hidden="true"></i></span>
                          
                          </p>
                          
                            
                       
                          
                          <div className="level-right" style={{width: `100%`}} key={props.productPrice}>
                            <h5 class="mb-0">{"RD$"+(productValue[prod.codigo] ? productValue[prod.codigo] : prod.solicitar_precio )}</h5>
                            <a href="#!" style={{color: `#cecece`}} onClick={()=>props.CartProdDeleteItem(prod.codigo)}><i class="fas fa-trash-alt"></i></a>
                          </div>
                          
                        </div> 
                      </div>
                    </div>
                  </div>
                
                )
        
        
           
    })
    )
  
  }
  
  
  }

  export default CartProdRender;