import axios from "axios";

export let allProd = {};

function axiosMainProductGet (a){

  console.log("insideAxiosB4")

 

    axios.get(a).then(res => {
        allProd=res.data;
         
            
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          })   
       

}

export default axiosMainProductGet;