import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';




function DescuentosListFunc (props){
    const IPMaindescuentosGet= "http://localhost:8000/api/descuentos/"

    const [descuentos, setDescuentos] = useState([]);
    const [viewOne, setViewOne] =useState(0);
    const [key1, setKey1]= useState("");
    const [key2, setKey2]= useState("");
    
    

  
    
     


    useEffect(() => {
      let mounted = true;

      
      

      axios.get(IPMaindescuentosGet).then(res => {
        if(mounted) {
          
       setDescuentos(res.data);
      
     
       console.log("data montada "+res.data.length);
        }
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          }) 
          return () => mounted = false;

        
       
        
      
    }, []); 

    /*
 
    useEffect(() => {
        console.log("axiosdata: "+allprodAxios());
        allprodAxios();
        if(!allProd){
            if(!descuentos){
              allprodAxios();
            }

        }else{
      setDescuentos(allProd);
        setKey1(key1+1);}
        
      }, [allProd, viewOne, key2]);  

      */
 



 



    


const hDescuentosList = () =>{

    return(
        <>
       
        {descuentos.map((a)=>{
                


                return(
                    <> 
                   
                    <tr key={`NSolicitud:${a.id}`} >
                    <td name={a.id} value={a} ><a>{a.id}</a></td>
                    <td name={a.id}>{a.codigo_descuento}</td>
                    <td name={a.id}>{a.descripcion_descuento}</td>
                    <td name={a.id}>{a.tipo_descuento}</td>
                    <td name={a.id}>{a.valor_descuento}</td>
                    <td name={a.id}>{a.estatus_descuento}</td>
                   
                    
                    </tr>
                    </>)
            }
            )}
        
        </>
    )

      }
    

    return(<>
       
              {hDescuentosList()}
                
         

        
<ToastContainer/>



        </>
    )
}

export default DescuentosListFunc;