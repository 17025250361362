
import logo1 from '../Assets/1.webp';
import logo2 from '../Assets/2.webp';
import logo3 from '../Assets/3.jpg';
import MBlogo1 from '../Assets/MundoBarato1.png';
import MBlogo2 from '../Assets/MundoBarato2.png';
import MBlogo3 from '../Assets/MundoBarato3.png';
import MBlogo4 from '../Assets/MundoBarato4.png';
import banner1 from '../Assets/a21.png'
import banner2 from '../Assets/banner2.jpeg'
import banner3a from '../Assets/banner3a.png'
import alMayorBanner1 from '../Assets/alMayorBanner.png';
import MBBanner2a from '../Assets/mundobaratobanner2a.png'
import whatMD from '../Assets/whatsapp.svg';
import IGMD from '../Assets/instagram.svg';
import TIKTKMD from '../Assets/tiktok.png';
import MULTILOGO1 from '../Assets/Multilogo1.png';
import flag1 from '../Assets/RDflag.gif'
import carru1 from '../Assets/carru1a.png'
import carru2 from '../Assets/carru2.png'
import carru3 from '../Assets/carru3.png'
import MainProductList, { allCartProd, departmentsi, prodDataToFilter } from '../FunctionalComponents/MainProductList';
import Icon from '@mdi/react';
import { mdiCartVariant } from '@mdi/js';
import { useEffect, useState } from 'react';
import CarritoOne from './CarritoOne';
import { Link, Outlet, json } from 'react-router-dom';
import LoginOne from '../FunctionalComponents/Login';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselItem } from 'mdb-react-ui-kit';
import { Autocomplete, Badge, Box, Fab, TextField, Typography } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer } from 'react-toastify';
import data from '../FunctionalComponents/dept3.json';
import BarTop2 from '../FunctionalComponents/BarTop2';
import tramabg from '../Assets/tramabg.png'

import Collapss from '../FunctionalComponents/Collapss';
import Paginate from '../FunctionalComponents/Paginate';
import NavBarOne from '../FunctionalComponents/NavbarOne';
import FooterOne from '../FunctionalComponents/FooterOne';


export let navBar1=()=>{};

function LandingOne () {
  const [seen, setSeen] = useState(true);
  const [dataToFilter, setDataToFilter] = useState([]);
  const [filter1, setFilter1] = useState([]);
  const [key1, setKey1]=useState([]);
  const [prodListi, setProdListi] = useState({lngth:0});
  const [ppList1, setPPList1] = useState([]);
  const [srchOne, setSrchOne] = useState([]);

  const [cartCountOne, setCartCountOne] = useState(0);

  const [priceE, SetPriceE] =useState("˅");
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [categoriaFilter, setCategoriaFilter] = useState([]);

  let dat1 = data.BACK_TO_SCHOOL;
  let dat2 = data.BEBES;
  let dat3 = data.BELLEZA;
  let dat4 = data.CAMPING;
  let dat5 = data.DELIVERY;
  let dat6 = data.DEPORTE;
  let dat7 = data.ELECTRONICA;
  let dat8 = data.FERRETERIA;
  let dat9 = data.FIESTA;
  let dat10 = data.GAMING;
  let dat11 = data.HERRAMIENTA;
  let dat12 = data.HOGAR;
  let dat13 = data.JUGUETES;
  let dat14 = data.MASCOTAS;
  let dat15 = data.MODAYBELLEZA;
  let dat16 = data.MUNDOREPUESTOS;
  let dat17 = data.OFICINA;
  let dat18 = data.PRINCIPAL;
  let dat19 = data.REPUESTOS;
  let dat20 = data.SALUD;
  let dat21 = data.SEGURIDAD;
  let dat22 = data.VEHICULO;
  let pList1=[];

  let showBlock = "none";
  

  let prd1 = 0;
  let renderAfProds = "a";

  let colpsss=true;
  let margin="0px";
  let allaprod=0;


 let acti1="active";
 let acti2="";
 let acti3="";
 let priceSign="";
 
 let deptoCaptoObj={};

  useEffect(() => {
    setProdListi("a"+1);
    setProdListi({...prodListi, lngth: prodListi.lngth=allCartProd.length});
    
    allaprod=JSON.parse(localStorage.getItem('allCartProdLocal'));
    if(!allaprod){}else{
    setCartCountOne(allaprod.length);
    }
    console.log("prodlisti: "+prodListi.lngth);
  
   
     
   }, [allCartProd.length, allCartProd]); 

   useEffect(() => {
    prd1=allCartProd.length+"a";
    if(allCartProd.length<1){}else{
      renderAfProds=1;
      console.log("rendered after prod load? "+renderAfProds);}
   
     
   }, [prodListi]); 

   useEffect(() => {
    if(prodDataToFilter){
    prodDataToFilter.forEach((a, index) => {
      ppList1.push(<option key={index} value={a.descripcion}>{a.descripcion}</option>);
  })}
  setKey1(...key1, key1+"a");
   }, [prodDataToFilter]); 

   useEffect(() => {
    setDataToFilter(srchOne);
     
   }, [srchOne]); 


   function dynamicSortMenorA(property) {
    var sortOrder = 1;
   


    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
function dynamicSortMayorA(property) {
  var sortOrder = 1;
 


  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

//Function DynamicSort call example: People.sort(dynamicSort("Name"));

/*
   function navBar1 (){

    
  
  
  
    
    function prd2Func (props) {
      let a=1;
  
      if (a<=props){
  
        return(
          <Badge key={prodListi} badgeContent={ props} color="error">
                     <Icon path={mdiCartVariant}  size={1} color={"red"} />
                     {()=>{console.log("es < que");a=props}}
                    </Badge>
          )
  
      }else{
  
      return(
      <Badge key={prodListi} badgeContent={ props} color="error">
                 <Icon path={mdiCartVariant}  size={1} color={"red"} />
                 {console.log("no es < que")}
                </Badge>
      )}
     }
  
      return(
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
         
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <img className='d-inline' src={MBlogo1}  style={{width:`5%`,marginLeft:`2px`}} alt='...' />
          <figure class="image is-128x128">
          <img className='is-rounded' src={flag1}  alt="loading..." />
          </figure>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                        
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Dropdown
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#">Disabled</a>
              </li>
             
            </ul>
         
  
            
                                          
  
              <div className='pr-1' >
                <Link to={`/carrito`}>
                 <div className='btn' key={allCartProd}>
                
                    {prd2Func (prodListi.lngth)}
                
                </div>
                </Link>
              </div>
              <div className='w-25' >
                <LoginOne/>
              </div>
              
            
          </div>
        </nav>
      )
     }*/


 

// Inside your component's return...


   const prd2Func =(props)=> {
    let a=1;

    if (a<=props){

      return(<Link to={`/carrito`}>
        <Badge key={prodListi} badgeContent={ props} color="error">
                   <Icon path={mdiCartVariant}  size={1} color={"#107EC1"} />
                   {()=>{a=props}}
                  </Badge></Link>
        )

    }else{

    return(<Link to={`/carrito`}>
    <Badge key={prodListi} badgeContent={ prodListi.length} color="error">
               <Icon path={mdiCartVariant}  size={1} color={"#107EC1"} />
               {console.log("no es < que")}
              </Badge></Link>
    )}
   }

   


  const filterTwo = () =>{
    console.log(filter1);
    
    let aa = [];
    let cc={};
    console.log("New filtered array"+aa);
    if(filter1[0]===""){
      filter1.splice(0,1);
      
    }

    filter1.map((t)=>

      prodDataToFilter.map((p)=> {
        
    if(p.categoria==t){
      console.log(t);
      
      
      aa.push(p)
      
      
    }}
  
  ))
 
  console.log("New filtered array: "+aa.length);
  setDataToFilter(aa);
    
}

const filterPrice = () =>{
 const a=[...prodDataToFilter];

    if(priceE==="˅"){  
      setDataToFilter(a.sort(dynamicSortMayorA("solicitar_precio")));
      
      SetPriceE("˄");
      
    } else{
      setDataToFilter(a.sort(dynamicSortMenorA("solicitar_precio")));
      
      SetPriceE("˅");
    }
  
}

const filterNew = () =>{
  
 
    
       setDataToFilter(prodDataToFilter.sort(dynamicSortMayorA("codigo")));
       
   
       
    
   
 }




    function togglePop () {
        setSeen(!seen);
        
    };

    const actions = [
      { icon:<>
               {prd2Func (cartCountOne) }
               </>
                , name: 'Carrito' },
      { icon: <a href={`https://wa.me/18496544656?text=Hola%20solicito%20información`}> <img src={whatMD} style={{width:`90%`}} alt='Whatsapp' /></a>, name: 'Whatsapp' },
      { icon:<a href={`https://www.instagram.com/mundobaratord`}><img src={IGMD} style={{width:`90%`}} alt='IG' /></a> , name: 'Instagram' },
      { icon:<a href={`https://www.tiktok.com/@mundobaratord`}><img src={TIKTKMD} style={{width:`90%`}} alt='TikTok' /></a> , name: 'TikTok' },
    ];

    const ProdList1 = (props) => {

      let a=[];
  let list1 = [];

  switch (props.campo) {
    case "Prod":
      a="Prod1";
      break;
   
    default:
      break;
  }
  
  
  if (!a){}else{
    prodDataToFilter.forEach((a, index) => {
      list1.push(<option key={index} value={a.descripcion}>{a.descripcion}</option>);
  });}
  
 
return(

  <datalist id="data">
                                              
                                              {ppList1}
                                              </datalist>
  

)
     

    }


    const filterOneCheck= (arr, valor ) => {
        
      return arr.some((arrVal)=> valor === arrVal);


    }

    const filterOne = (prop,deptu) => {
      let capto = [];
      let capthree =[];
      let countCapto=0;
      
      prodDataToFilter.map((capt)=>{ if(capt.departamento===deptu) { capto.push(capt.categoria);countCapto++;   Object.assign(deptoCaptoObj, {deptu:deptu,capto:capto})}});      
   
      capto=capto.filter((value, index) => capto.indexOf(value) === index);
      capto=capto.sort();
     
      if(!capto){}else{
      return(
        capto.map((d) => {
          let categoria = d.toLowerCase();
          return(<div className='hoverBGColor hoverColor' style={{width:`100%`,display:`grid`, alignItems:`start`, justifyItems:`left`, margin:`0`, padding:`0`}} >
            
            <label  class="checkbox hoverColor" style={{fontSize:`85%`, textTransform:`capitalize`}} >
              <input type="checkbox" onClick={(e)=>{ if(filterOneCheck(filter1, d)){ filter1.splice(filter1.indexOf(d),1)}else{ filter1.push(d)}; filterTwo();console.log(filter1)}}/>
               {` ${categoria}`}
               
              </label>      
         
          
        
        </div>
          )
        })
      )}

    };

    
    const TiktokComponent = () => {

       
      const loadScript = (url) => {
          return new Promise((resolve, reject) => {
      
            if (document.getElementById('tiktok-script')) {
              resolve("loaded");
            }
            const script = document.createElement("script");
            script.async = true;
            script.src = url;
            script.setAttribute('id', 'tiktok-script');
      
            script.onload = () => {
              // script is loaded successfully, call resolve()
              resolve("loaded");
            };
      
            script.onerror = () => {
              // script is not loaded, call reject()
              reject("error");
            };
      
            document.head.appendChild(script);
          });
        }
    
      
        loadScript('https://www.tiktok.com/embed.js').then(status => {
          if (status === 'loaded') {
            showBlock = "block";
          }
        })
    
     
    
    }

    const departmentNewFilter =()=>{


      departmentFilter.sort();
      return(
      departmentFilter.map((dept) => {
    
        return(    
        <li style={{margin:`0`, padding:`0`}}>
        <Collapss children={filterOne(categoriaFilter,dept)} collapsed={true} name={<><strong className='hoverColor'>{dept}</strong></>} >
        </Collapss></li>)
        
      }
        
      ))

    }


    return( 
      <>   {TiktokComponent()}
      <div className='desk'>
    <NavBarOne func1={prd2Func(cartCountOne)} srch1={setSrchOne} where="main"/>
    </div>
        <div style={{backgroundImage:`url(${tramabg})`}} >
         
       
                       
      
       
       
      

    <div className='desk' style={{ position: 'fixed', bottom: 16, right: 16, zIndex:2 }}> 
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1}}>
      <SpeedDial 
        ariaLabel="SpeedDial openIcon example"
       
        FabProps={{
          sx: {
            bgcolor: '#00000000',
            '&:hover': {
              bgcolor: '#00000000',
            }
          }
        }}
        icon={<SpeedDialIcon sx={{position:`fixed`,bottom:48, right:2}} icon={<img src={MULTILOGO1} style={{width:`80%`}} alt='TikTok' />} openIcon={<EditIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
    </div>

    
     
      


    <Outlet />




          <img className='desk' src={banner3a}  width="100%" height="40%" style={{zIndex:`-1`,marginBottom:`0.5%`}} />
         
          
           
    
          <div className='mob'>


          <div  style={{ position: 'fixed', bottom: 4, right: 4, zIndex:2 }}> 
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1}}>
      <SpeedDial 
        ariaLabel="SpeedDial openIcon example"
       
        FabProps={{
          sx: {
            bgcolor: '#00000000',
            '&:hover': {
              bgcolor: '#00000000',
            }
          }
        }}
        icon={<SpeedDialIcon sx={{position:`fixed`,bottom:48, right:2}} icon={<img src={MULTILOGO1} style={{width:`80%`}} alt='TikTok' />} openIcon={<EditIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
    </div>


    <div class="fixed-grid has-5-cols center" >
              <div class="navbar-brand grid">
             
           
            <div class="cell">
                <Collapss children={<>  <div className='box'><Collapss children={<><ul class="menu-list" style={{color:`000`, display:`grid`, alignItems:`start`, justifyItems:`left`}}>{departmentNewFilter()}</ul></>} collapsed={true} name={<>
                    DEPARTAMENTOS
                      
                    </>} >
              </Collapss></div>
              <div class="box " onClick={()=>{filterNew()}}>Nuevo</div>
              
              
              <div class="box" onClick={()=>{ setFilter1([""])}}>Destacado</div>

            
              <div class="box " key={acti3}  onClick={()=>{filterPrice(); }}>Precio {priceE}</div>
              
              
              
              </>} collapsed={true} name={<> 
                  <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"> <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span></a>
                </>} >
              </Collapss>
             
              </div>
              <div className='cell'><Link to={`/`}> <img className='image' width="104" src={MBlogo1} style={{marginLeft:`5%`}}  /></Link>
              </div>
              
              <div className='cell'></div>
              <div className='cell'>
              {prd2Func(cartCountOne)}</div>
              </div></div>
            
            
              
              <img src={banner3a}  width="100%" height="40%" style={{zIndex:`-1`,marginBottom:`0.5%`}} />


       
          <div class="container" style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
        
        <div class="card " style={{backgroundColor:`none`, backgroundColor:"#FFFFFF55"}}> 
   
   
    <MainProductList  data1={dataToFilter} filter1={filter1} deptFilter={setDepartmentFilter} catFilter={setCategoriaFilter} style={{backgroundColor:`none`,backgroundColor:"#FFFFFF88"}}/>
    </div></div>
          </div>





                    {//********************VERSION DE ESCRITORIO**************************
                    }
    <div class="fixed-grid has-4-cols desk" >
    <div className='grid '  >
        <div className='cell is-col-span-1' >
          <div className='panel' style={{backgroundColor:`#FFFFFFAA` }}>
        <div className='panel-heading' style={{fontSize:`95%`, backgroundColor:`#107EC1`}}>
          <div className='level'><div className='level-left'>DEPARTAMENTOS</div>  <div className='level-right button is-small is-light'  onClick={()=>{ window.location.reload(false);}}><i class="fa fa-refresh" aria-hidden="true"/></div></div>
        </div>
   
       <div className='menu' >
  <ul class="menu-list" style={{color:`000`, display:`grid`, alignItems:`start`, justifyItems:`left`}}>
        {departmentFilter?  departmentNewFilter(): " " }    
      </ul>
      </div>
      </div>
      
      <div className='panel' style={{backgroundColor:`#FFFFFFAA` }}>
      <blockquote class=" tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7413896143597227270" data-video-id="7413896143597227270" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a></section> </blockquote>
        </div>

        <MDBCarousel showIndicators showControls fade>
          <MDBCarouselItem itemId={1}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.391641619399!2d-69.9708139!3d18.4792237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf8bc457d22271%3A0x5c2ef1c75ca2c747!2sMundo%20Barato!5e0!3m2!1sen!2sve!4v1721340359873!5m2!1sen!2sve" height="400px"  style={{border:`0`}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
            <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTO DOMINGO</h5></strong>
              <p style={{color:`#FFF`}}>Km 9 Autopista Duarte</p>
            </MDBCarouselCaption>
          
          </MDBCarouselItem>

          <MDBCarouselItem itemId={2}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5351.257031419962!2d-69.83603419177697!3d18.48772933538145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf87b9d4dce0e5%3A0x1b4aea9871257623!2sCoral%20Mall!5e0!3m2!1sen!2sve!4v1721348033692!5m2!1sen!2sve" height="400px" style={{border:`0`}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>CORAL MALL</h5></strong>
              <p style={{color:`#FFF`}}>Segundo piso</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem itemId={3}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60191.970428118875!2d-70.7754642783203!3d19.455646499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1cfd67d0d1bd9%3A0x16e7cf40f1b1946b!2sMundo%20Barato%20Stg!5e0!3m2!1sen!2sve!4v1721348119133!5m2!1sen!2sve" height="400px"  style={{border:`0`}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTIAGO</h5></strong>
              <p style={{color:`#FFF`}}>27 de Febrero</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </MDBCarousel>


    </div>
   
    <div className='cell is-col-span-3' style={{background:`none`}}>
    <div class="container" style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
        
        <div class="card text-center" style={{backgroundColor:`none`, backgroundColor:"#FFFFFF55"}}> 
   <div class="card-header" style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
    <div className='grid' style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
    
      

    
        <div class="button" onClick={()=>{filterNew()}}>Nuevo</div>
   
   
        <div class="button" onClick={()=>{ setFilter1([""])}}>Destacado</div>
    
      
        <div class="button" key={acti3}  onClick={()=>{filterPrice(); }}>Precio {priceE}</div>
      
    </div>
  </div>
    <MainProductList  data1={dataToFilter} filter1={filter1} deptFilter={setDepartmentFilter} catFilter={setCategoriaFilter} style={{backgroundColor:`none`,backgroundColor:"#FFFFFF88"}}/>
    </div></div>
    </div>
    </div></div>


        
    <FooterOne/>
    <ToastContainer/>
    </div></>
    )
    
}

export default LandingOne;