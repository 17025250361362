import { Link } from 'react-router-dom';
import FachadaIMG from '../Assets/Fachada1.jpg'
import NavBarOne from '../FunctionalComponents/NavbarOne';
import FooterOne from '../FunctionalComponents/FooterOne';
import whatMD from '../Assets/whatsapp.svg';
import banner1 from '../Assets/bnner1.jpeg'
import { MDBCarousel, MDBCarouselCaption, MDBCarouselItem } from 'mdb-react-ui-kit';
import { useEffect } from 'react';

function QuienesSomos(){
    let showBlock = "none";

    

   const TiktokComponent = () => {

       
        const loadScript = (url) => {
            return new Promise((resolve, reject) => {
        
              if (document.getElementById('tiktok-script')) {
                resolve("loaded");
              }
              const script = document.createElement("script");
              script.async = true;
              script.src = url;
              script.setAttribute('id', 'tiktok-script');
        
              script.onload = () => {
                // script is loaded successfully, call resolve()
                resolve("loaded");
              };
        
              script.onerror = () => {
                // script is not loaded, call reject()
                reject("error");
              };
        
              document.head.appendChild(script);
            });
          }
      
        
          loadScript('https://www.tiktok.com/embed.js').then(status => {
            if (status === 'loaded') {
              showBlock = "block";
            }
          })
      
       
      
      }

    return(<>
    {TiktokComponent()}
    <NavBarOne where={"quienesSomos"}></NavBarOne>
    
    <div style={{position:`relative`,textAlign:`center`}}
        >
        <img  src={banner1} alt='...'  style={{width:`100%`,marginBottom:`0.5%`}}/>
        <div class="title is-family-monospace" style={{position:`absolute`,top:`40%`,left:`10%`,color:`#FFF`,fontSize:`3em` }}>POLÍTICAS</div>
        </div>

    <div className='desk'>
    <div className='level ' style={{backgroundColor:`yellow`}}>
                <div className='level-item'></div>
                    <div className="level-item marquee enable-animation">
                        <div className="marquee__content">
                
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento!</strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                        </div>
                    </div>
                <div className='level-item'></div>
            
            </div>
        <div className='fixed-grid has-4-cols level'>
     
        <div className='grid'>
       
        
        <div className='cell is-col-span-3' style={{marginTop:`20%`}}>
       
       
                      <p className='subtitle' style={{textWrap:`pretty`}}>
              
                      <h5 className="title"  style={{display:`grid`,placeItems:`center`}}>
                      ¿Quiénes somos?</h5>
        
             
                Todo comenzó con una tienda virtual que creamos en Enero de 2019 en Santo Domingo, Rep. Dom. Nos dedicábamos a vender cigarrillos electrónicos y luego migramos a vender productos electrónicos en general. Nuestro catálogo de productos se fue ampliando conforme los clientes nos iban pidiendo variedad de artículos, es por eso que hoy nuestro catalogo cuenta con más de 2000 artículos de categorías variadas (Deportivos, Tácticos, para Camping, Electrodomésticos, Juguetes, etc.), para satisfacer los clientes más exigentes.  En 2020 inauguramos nuestra primera tienda física; Hoy en día contamos con 3 sucursales y nuestro sueño es llegar a todas las provincias del país.  

                Nuestro lema se basa en que si no lo tenemos… lo buscamos por ti. Todo lo que necesitas lo puedes encontrar aquí en Mundo Barato.  

                Actualmente, más de 150,000 seguidores en Instagram, Facebook y Tiktok forman parte de nuestra familia, los cuales se han convertido en nuestro móvil principal para seguir en constante crecimiento. 

                Nuestros seguidores respaldan nuestro compromiso con la atención al cliente y recomiendan nuestra tienda por tener los mejores precios del mercado. 

                En Mundo Barato Vendemos Barato, Barato… BARATO!!!
                </p>
            
             </div>
            <div className='cell is-col-span-1' style={{height:`min-content`}}>
           
                
            <blockquote class=" tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7410413394345381125" data-video-id="7410413394345381125" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a></section> </blockquote>
                
            </div>
     
          
            
            
        
        </div></div>

        <div class="grid box">
        <div class="cell ">
        <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>INSTAGRAM</h3>
            
            <span><h4>120k <i class="fa fa-users" aria-hidden="true"></i> </h4></span>
    				 
    			</div>
    			<progress class="progress is-success" value="100" max="100">
            120.000
          </progress>
    			<div class="tile-footer">
    			<h4>
            <span class="pct-counter">15.5%</span>Aumento
    				</h4>
    				<span> Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      <div class="cell ">
      <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>FACEBOOK</h3>
            <span><h4>50k  <i class="fa fa-users" aria-hidden="true"></i></h4></span>
    			</div>
    			<progress class="progress is-link" value="35" max="100">
            50.000
          </progress>
    			<div class="tile-footer">
    				<h4>
            <span class="pct-counter">7%</span>Aumento
    				</h4>
    				<span>Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      <div class="cell ">
        <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>TIKTOK</h3>
    				<span><h4>50k  <i class="fa fa-users" aria-hidden="true"></i></h4></span>
    			</div>
    			<progress class="progress is-info" value="35" max="100">
            50.000
          </progress>
    			<div class="tile-footer">
    				<h4>
            <span class="pct-counter">12%</span>Aumento
    				</h4>
    				<span>Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      
    
    </div>

        <MDBCarousel showIndicators showControls fade style={{height:"400px"}}>
          <MDBCarouselItem itemId={1}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.391641619399!2d-69.9708139!3d18.4792237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf8bc457d22271%3A0x5c2ef1c75ca2c747!2sMundo%20Barato!5e0!3m2!1sen!2sve!4v1721340359873!5m2!1sen!2sve" height="400px" style={{border:`0`, width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
            <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTO DOMINGO</h5></strong>
              <p style={{color:`#FFF`}}>Km 9 Autopista Duarte</p>
            </MDBCarouselCaption>
          
          </MDBCarouselItem>

          <MDBCarouselItem itemId={2}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5351.257031419962!2d-69.83603419177697!3d18.48772933538145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf87b9d4dce0e5%3A0x1b4aea9871257623!2sCoral%20Mall!5e0!3m2!1sen!2sve!4v1721348033692!5m2!1sen!2sve" height="400px"  style={{border:`0`, width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>CORAL MALL</h5></strong>
              <p style={{color:`#FFF`}}>Segundo piso</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem itemId={3}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60191.970428118875!2d-70.7754642783203!3d19.455646499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1cfd67d0d1bd9%3A0x16e7cf40f1b1946b!2sMundo%20Barato%20Stg!5e0!3m2!1sen!2sve!4v1721348119133!5m2!1sen!2sve"  height="400px"  style={{border:`0`, width:"100%"}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTIAGO</h5></strong>
              <p style={{color:`#FFF`}}>27 de Febrero</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </MDBCarousel>
        <br/>

   


        <div className='grid'>
        <div className='cell ' style={{backgroundColor:`#FFFFFFAA` }}>
      <blockquote class=" tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7410413394345381125" data-video-id="7410413394345381125" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a></section> </blockquote>
        </div>
        <div className='cell ' style={{backgroundColor:`#FFFFFFAA` }}>
      <blockquote class="tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7408993921507904774" data-video-id="7408993921507904774" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a> </section> </blockquote>
        </div>
        <div className='cell ' style={{backgroundColor:`#FFFFFFAA` }}>
      <blockquote class="tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7408921573903699206" data-video-id="7408921573903699206" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a> </section> </blockquote>
        </div>
        </div>



       
	

        </div>


        <div className='mob'>
    <div className='level ' style={{backgroundColor:`yellow`}}>
                <div className='level-item'></div>
                    <div className="level-item marquee enable-animation">
                        <div className="marquee__content">
                
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento!</strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                        </div>
                    </div>
                <div className='level-item'></div>
            
            </div>
    
       
        
        <div className='cell is-col-span-3' style={{marginTop:`20%`}}>
       
       
                      <p className='subtitle' style={{textWrap:`pretty`}}>
              
                      <h5 className="title"  style={{display:`grid`,placeItems:`center`}}>
                      ¿Quiénes somos?</h5>
        
             
                Todo comenzó con una tienda virtual que creamos en Enero de 2019 en Santo Domingo, Rep. Dom. Nos dedicábamos a vender cigarrillos electrónicos y luego migramos a vender productos electrónicos en general. Nuestro catálogo de productos se fue ampliando conforme los clientes nos iban pidiendo variedad de artículos, es por eso que hoy nuestro catalogo cuenta con más de 2000 artículos de categorías variadas (Deportivos, Tácticos, para Camping, Electrodomésticos, Juguetes, etc.), para satisfacer los clientes más exigentes.  En 2020 inauguramos nuestra primera tienda física; Hoy en día contamos con 3 sucursales y nuestro sueño es llegar a todas las provincias del país.  

                Nuestro lema se basa en que si no lo tenemos… lo buscamos por ti. Todo lo que necesitas lo puedes encontrar aquí en Mundo Barato.  

                Actualmente, más de 150,000 seguidores en Instagram, Facebook y Tiktok forman parte de nuestra familia, los cuales se han convertido en nuestro móvil principal para seguir en constante crecimiento. 

                Nuestros seguidores respaldan nuestro compromiso con la atención al cliente y recomiendan nuestra tienda por tener los mejores precios del mercado. 

                En Mundo Barato Vendemos Barato, Barato… BARATO!!!
                </p>
            
             </div>
            <div className='cell is-col-span-1' style={{height:`min-content`}}>
           
                
            <blockquote class=" tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7410413394345381125" data-video-id="7410413394345381125" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a></section> </blockquote>
                
            </div>
     
          
            
            
        
        

        <div class="grid box">
        <div class="cell ">
        <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>INSTAGRAM</h3>
            
            <span><h4>120k <i class="fa fa-users" aria-hidden="true"></i> </h4></span>
    				 
    			</div>
    			<progress class="progress is-success" value="100" max="100">
            120.000
          </progress>
    			<div class="tile-footer">
    			<h4>
            <span class="pct-counter">15.5%</span>Aumento
    				</h4>
    				<span> Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      <div class="cell ">
      <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>FACEBOOK</h3>
            <span><h4>50k  <i class="fa fa-users" aria-hidden="true"></i></h4></span>
    			</div>
    			<progress class="progress is-link" value="35" max="100">
            50.000
          </progress>
    			<div class="tile-footer">
    				<h4>
            <span class="pct-counter">7%</span>Aumento
    				</h4>
    				<span>Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      <div class="cell ">
        <div className='card'>
    		<div class="tile-progress tile-primary">
    			<div class="tile-header">
    				<h3>TIKTOK</h3>
    				<span><h4>50k  <i class="fa fa-users" aria-hidden="true"></i></h4></span>
    			</div>
    			<progress class="progress is-info" value="35" max="100">
            50.000
          </progress>
    			<div class="tile-footer">
    				<h4>
            <span class="pct-counter">12%</span>Aumento
    				</h4>
    				<span>Porcentaje de aumento</span>
    			</div>
    		</div></div>
    	</div>
      
    
    </div>

        <MDBCarousel showIndicators showControls fade style={{height:"400px"}}>
          <MDBCarouselItem itemId={1}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.391641619399!2d-69.9708139!3d18.4792237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf8bc457d22271%3A0x5c2ef1c75ca2c747!2sMundo%20Barato!5e0!3m2!1sen!2sve!4v1721340359873!5m2!1sen!2sve" height="400px" style={{border:`0`, width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
            <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTO DOMINGO</h5></strong>
              <p style={{color:`#FFF`}}>Km 9 Autopista Duarte</p>
            </MDBCarouselCaption>
          
          </MDBCarouselItem>

          <MDBCarouselItem itemId={2}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5351.257031419962!2d-69.83603419177697!3d18.48772933538145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf87b9d4dce0e5%3A0x1b4aea9871257623!2sCoral%20Mall!5e0!3m2!1sen!2sve!4v1721348033692!5m2!1sen!2sve" height="400px"  style={{border:`0`, width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>CORAL MALL</h5></strong>
              <p style={{color:`#FFF`}}>Segundo piso</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem itemId={3}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60191.970428118875!2d-70.7754642783203!3d19.455646499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1cfd67d0d1bd9%3A0x16e7cf40f1b1946b!2sMundo%20Barato%20Stg!5e0!3m2!1sen!2sve!4v1721348119133!5m2!1sen!2sve"  height="400px"  style={{border:`0`, width:"100%"}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <MDBCarouselCaption style={{backgroundColor:`#0085c9CC`}}>
              <strong style={{color:`#FFF`}}><h5>SANTIAGO</h5></strong>
              <p style={{color:`#FFF`}}>27 de Febrero</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </MDBCarousel>
        <br/>

   


   
        <div  style={{backgroundColor:`#FFFFFFAA` }}>
      <blockquote class="tiktok-embed panel is-0-mobile" cite="https://www.tiktok.com/@mundobaratord/video/7408993921507904774" data-video-id="7408993921507904774" style={{minWidth:`inherit`}} > <section> <a target="_blank" title="@mundobaratord" href="https://www.tiktok.com/@mundobaratord?refer=embed">@mundobaratord</a> </section> </blockquote>
        </div>
       


       
	

        </div>


        <FooterOne />
        </>
    )
}

export default QuienesSomos;