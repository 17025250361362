import { Link } from 'react-router-dom';
import FachadaIMG from '../Assets/Fachada1.jpg'
import NavBarOne from '../FunctionalComponents/NavbarOne';
import FooterOne from '../FunctionalComponents/FooterOne';
import banner1 from '../Assets/bnner1.jpeg'

function Politicas(){

  

    return(<>
        <NavBarOne where={"politicas"}></NavBarOne>
        
        <div style={{position:`relative`,textAlign:`center`}}
        >
        <img  src={banner1} alt='...'  style={{width:`100%`,marginBottom:`0.5%`}}/>
        <div class="title is-family-monospace" style={{position:`absolute`,top:`40%`,left:`10%`,color:`#FFF`}}>POLÍTICAS</div>
        </div>



        <div className='level ' style={{backgroundColor:`yellow`}}>
                <div className='level-item'></div>
                    <div className="level-item marquee enable-animation">
                        <div className="marquee__content">
                
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento!</strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                        </div>
                    </div>
                <div className='level-item'></div>
            
            </div>
            
            <div className='fixed-grid has-4-cols desk' >
            
            <div className='grid' >
            <div className='cell is-col-span-1'></div>

            <div className='cell box is-col-span-2'>
                <p style={{textWrap:`pretty`}}>
               
                    <p className="subtitle">
                    
                    En MUNDOBARATO, estamos <strong>comprometidos con la satisfacción</strong> de nuestros clientes. Es por ello, que hemos creado una política justa que satisfaga las necesidades de nuestros clientes. 
                    Por favor, siga las políticas de garantía a continuación:
                    
                    </p>
                    <ol >
                    <li>
                   Después que hayas ordenado y recibido tu orden, tendrás 7 días de garantia para cualquier cambio o devolución de un artículo defectuoso o que no se corresponda con su descripción. Pasados los 7 días, no somos responsables de cualquier inconveniente que pueda ocurrir. 
                   </li><li>
                    Para solicitar la información de devolución, (1) contáctenos a nuestro correo electrónico Info@mundobaratord.com, (2) a nuestros números de teléfono y Whatsapp, (3) o directamente llenando el formulario de contacto haciendo click aquí. Favor enviar una foto del paquete defectuoso o alguna prueba que indique la validez de su reclamación dentro del tiempo indicado en el párrafo anterior. Deberás también adjuntar una foto de la factura anexada al paquete que se te envió, de lo contrario; no serás elegible para cambio o devolución.
                    </li><li>
                    En caso de devolución de un producto (siempre que no sea defectuoso), los gastos de transporte, tanto de entrega como devolución que correspondan, seran de cargo del comprador así como todos los gastos bancarios generados a cuenta de esta transacción. La devolución del importe se efectuará mediante NOTA DE CREDITO, nunca en efectivo.
                    </li><li>
                    Los cambios o reclamaciones no son validos cuando se compruebe que la averia fue ocasionada por el uso inadecuado del cliente. La garantia no cubre golpes, ni roturas.
                    </li><li>
                    No aceptamos artículos para devolución de dinero a menos que los artículos estén defectuosos causados por el vendedor (MUNDOBARATORD) En caso de ser elegible, los artículos serán reemplazados a la mayor disponibilidad posible.
                    </li><li>
                    Para ser elegible para una devolución de un artículo que no esté dañado, su artículo debe estar sin usar, empacado en las mismas condiciones en que lo recibió. Favor enviar una foto o video que pruebe que el artículo está justo como lo recibió. 
                    </li><li>
                    Los regalos canjeados no se pueden devolver y no se pueden cambiar por dinero en efectivo.
                    </li><li>
                    Todos los gastos ocasionados por la devolución correrán a cargo del comprador.
                    </li><li>
                    Antes de solicitar una devolución, fotografíe el artículo para sus registros.
                    </li><li>
                    Las devoluciones sin el embalaje original, factura o comprobante de compra serán rechazadas.
                    </li><li>
                    Si la caja de empaque está dañada no será aceptada para devolución.
                    </li><li>
                    Si su devolución es aprobada, deberá esperar de 1-3 días hábiles para recibir nuevamente el paquete.
                    </li>
                    </ol>
                    <p>
                    Reembolsos (si corresponde)

                    Una vez recibido e inspeccionado el artículo devuelto, se procesará su reembolso a travez de nota de credito para ser canjeado en tienda en un lapso no mayor a 60 dias. 

                    Reembolsos atrasados o faltantes (si corresponde)

                    Si no ha recibido respuesta, contáctenos a info@mundobaratord.com o a nuestros números de teléfono y Whatsapp. 

                    Atentamente, 
                    Gerencia Administrativa Mundo barato
                    </p>
                    </p>
                </div>
                <div className='cell is-col-span-1'>

                    <div className='box'>
                   <strong>SOMOS MAYORISTAS </strong>
                    A partir de 6 productos mejoramos el precio de su compra
                    <div className='card-footer center'><a style={{color:`blue`}}>IG @mundobaratord</a></div>
                    </div>

                </div>
               
            
                
                </div>
            </div>



       
           
            <div className='box'>
                   <strong>SOMOS MAYORISTAS </strong>
                    A partir de 6 productos mejoramos el precio de su compra
                    <div className='card-footer center'><a style={{color:`blue`}}>IG @mundobaratord</a></div>
                    </div>

          
                <p style={{textWrap:`pretty`}}>
               
                    <p className="subtitle">
                    
                    En MUNDOBARATO, estamos <strong>comprometidos con la satisfacción</strong> de nuestros clientes. Es por ello, que hemos creado una política justa que satisfaga las necesidades de nuestros clientes. 
                    Por favor, siga las políticas de garantía a continuación:
                    
                    </p>
                    <ol >
                    <li>
                   Después que hayas ordenado y recibido tu orden, tendrás 7 días de garantia para cualquier cambio o devolución de un artículo defectuoso o que no se corresponda con su descripción. Pasados los 7 días, no somos responsables de cualquier inconveniente que pueda ocurrir. 
                   </li><li>
                    Para solicitar la información de devolución, (1) contáctenos a nuestro correo electrónico Info@mundobaratord.com, (2) a nuestros números de teléfono y Whatsapp, (3) o directamente llenando el formulario de contacto haciendo click aquí. Favor enviar una foto del paquete defectuoso o alguna prueba que indique la validez de su reclamación dentro del tiempo indicado en el párrafo anterior. Deberás también adjuntar una foto de la factura anexada al paquete que se te envió, de lo contrario; no serás elegible para cambio o devolución.
                    </li><li>
                    En caso de devolución de un producto (siempre que no sea defectuoso), los gastos de transporte, tanto de entrega como devolución que correspondan, seran de cargo del comprador así como todos los gastos bancarios generados a cuenta de esta transacción. La devolución del importe se efectuará mediante NOTA DE CREDITO, nunca en efectivo.
                    </li><li>
                    Los cambios o reclamaciones no son validos cuando se compruebe que la averia fue ocasionada por el uso inadecuado del cliente. La garantia no cubre golpes, ni roturas.
                    </li><li>
                    No aceptamos artículos para devolución de dinero a menos que los artículos estén defectuosos causados por el vendedor (MUNDOBARATORD) En caso de ser elegible, los artículos serán reemplazados a la mayor disponibilidad posible.
                    </li><li>
                    Para ser elegible para una devolución de un artículo que no esté dañado, su artículo debe estar sin usar, empacado en las mismas condiciones en que lo recibió. Favor enviar una foto o video que pruebe que el artículo está justo como lo recibió. 
                    </li><li>
                    Los regalos canjeados no se pueden devolver y no se pueden cambiar por dinero en efectivo.
                    </li><li>
                    Todos los gastos ocasionados por la devolución correrán a cargo del comprador.
                    </li><li>
                    Antes de solicitar una devolución, fotografíe el artículo para sus registros.
                    </li><li>
                    Las devoluciones sin el embalaje original, factura o comprobante de compra serán rechazadas.
                    </li><li>
                    Si la caja de empaque está dañada no será aceptada para devolución.
                    </li><li>
                    Si su devolución es aprobada, deberá esperar de 1-3 días hábiles para recibir nuevamente el paquete.
                    </li>
                    </ol>
                    <p>
                    Reembolsos (si corresponde)

                    Una vez recibido e inspeccionado el artículo devuelto, se procesará su reembolso a travez de nota de credito para ser canjeado en tienda en un lapso no mayor a 60 dias. 

                    Reembolsos atrasados o faltantes (si corresponde)

                    Si no ha recibido respuesta, contáctenos a info@mundobaratord.com o a nuestros números de teléfono y Whatsapp. 

                    Atentamente, 
                    Gerencia Administrativa Mundo barato
                    </p>
                    </p>
              
                
               
            
              
    


            <FooterOne />
            </>
        )

}

export default Politicas;