import axiosMainProductGet, { allProd } from "../APIs/AxiosMainProductGet";
import imgprdi from "../Assets/MB1.png"
import camera1 from '../Assets/camera.jpg'
import talkiew from '../Assets/wtalkie.webp'
import pika from '../Assets/pokepika.jfif'
import grid1 from '../Assets/grid1.svg'
import grid2 from '../Assets/grid6.svg'
import { useEffect, useState } from "react";
import Icon from '@mdi/react';
import { mdiViewGridOutline,mdiCartArrowRight, mdiCartVariant  } from '@mdi/js';
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Paginate from "./Paginate";
import { serchbarPRD } from "./NavbarOne";


export let allCartProd = [];
export let prodDataToFilter = [];
export let departmentsi =[];


function MainProductList (props){
    const IPMainProductsGet= process.env.REACT_APP_APIMAINPRDGET2_URL;
    //const IPMainProductsGet= "https://postgre-mbrd.onrender.com/api/articulos2/";

    const [products, setProducts] = useState([]);
    const [viewOne, setViewOne] =useState(0);
    const [key1, setKey1]= useState("");
    const [key2, setKey2]= useState("");
    
    const imgPicSum = "https://picsum.photos/200/300?random=";

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(52);
  
    const [serchhValue, setSerchhValue] = useState();
  
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    

    let currentPosts=products.slice(indexOfFirstPost, indexOfLastPost);


    let skeleCards=["","","","","","","","","","","","","","","","","","","","",]
    let ppList1=[];
    let srcedProducts=[];
     


    useEffect(() => {
      let mounted = true;
      console.log("Data one changed")

      if(!props.data1[0]||!serchbarPRD[0]){
      

      axios.get(IPMainProductsGet).then(res => {
        if(mounted) {
          
       setProducts(res.data);
       prodDataToFilter=res.data;

       prodDataToFilter.map((prod)=>{
        let res = prod.solicitar_precio.replace(",", "");

        Object.assign(prod, {solicitar_precio:parseInt(res)})

       
    
      })
      let depto = []
      res.data.map((dept)=>{ depto.push(dept.departamento)});      
      props.deptFilter(depto.filter((value, index) => depto.indexOf(value) === index));

    
      
      
       
       console.log("dataVar: "+prodDataToFilter.length)
       console.log("data montada "+res.data.length);
        }
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          }) 
          return () => mounted = false;

        }
        else if (!serchbarPRD[0])
        {
        

          setProducts(props.data1);
          currentPosts=products.slice(indexOfFirstPost, indexOfLastPost);
          console.log("data filtrada?"+props.data1.length);



        } else{
          setProducts(serchbarPRD);
          currentPosts=products.slice(indexOfFirstPost, indexOfLastPost);
          console.log("data filtrada SErchbr?"+serchbarPRD.length);

        }
        
      
    }, [ props.filter1]); 

    useEffect(() => {
      let mounted = true;
      console.log("Data one changed")

      if(!props.data1[0]){
      

      axios.get(IPMainProductsGet).then(res => {
        if(mounted) {
          
       setProducts(res.data);
       prodDataToFilter=res.data;
       prodDataToFilter.map((prod)=>{
        let res = prod.solicitar_precio.replace(",", "");

        Object.assign(prod, {solicitar_precio:parseInt(res)})
    
      })
       
       console.log("dataVar: "+prodDataToFilter.length)
       console.log("data montada "+res.data.length);
        }
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          }) 
          return () => mounted = false;

        }
        else 
        {
        

          setProducts(props.data1);
          currentPosts=products.slice(indexOfFirstPost, indexOfLastPost);
          console.log("data filtrada?"+props.data1.length);



        } 
        
      
    }, [props.data1]); 

    /*
 
    useEffect(() => {
        console.log("axiosdata: "+allprodAxios());
        allprodAxios();
        if(!allProd){
            if(!products){
              allprodAxios();
            }

        }else{
      setProducts(allProd);
        setKey1(key1+1);}
        
      }, [allProd, viewOne, key2]);  

      */
 
      useEffect(() => {
        console.log(viewOne);
        
      }, [viewOne]); 

    
    useEffect(() => {
      products.forEach((a, index) => {
        ppList1.push(<option key={index} value={a.descripcion}>{a.descripcion}</option>);
    })
      
    }, [products]); 

 


const AddToCart = (e) => {
  let a=e;

  const goToCartTwo =()=>{
    return(<div style={{marginTop:`5%`}}>
      <Link to={`/carrito`}><Icon path={mdiCartArrowRight} size={1} color={"red"} /></Link>
      </div>
    )
  }

  if(!JSON.parse(localStorage.getItem('allCartProdLocal'))){
    localStorage.setItem('allCartProdLocal', "[]");
  }else{
  allCartProd=JSON.parse(localStorage.getItem('allCartProdLocal'));
  }

if(allCartProd.find((element) => element.codigo === a.codigo)){
    console.log("product found");
  }else{

    console.log("product NOT found");
    allCartProd.push(e);
    localStorage.setItem('allCartProdLocal', JSON.stringify(allCartProd));

    toast.success(`1 "${e.descripcion}" agregado al carrito`, {
      position: "top-right",
      closeButton:goToCartTwo
  
      });
  }




    
  

 
}

     
const previousPage = () => {
  if (currentPage !== 1) {
     setCurrentPage(currentPage - 1);
  }
};

const nextPage = () => {
  if (currentPage !== Math.ceil(products.length / postsPerPage)) {
     setCurrentPage(currentPage + 1);
  }
};

const paginate = (pageNumber) => {
 setCurrentPage(pageNumber);
};


      const PaginateOne = () => {
        
      
    
     


    
         
  let countProd=0;
  let imgRandomCount=0;
  let imgprdu="";
  
     
    
      
       if(!products||products.length<1) {
          return(<>
          {skeleCards.map((skeleCrd) =>{
            return(
          <>
          
         
         
            <div class="cell card" >


                <div class="card-image center is-skeleton" style={{margin:`0.5rem`}}>
                <figure class="image is-128x128 is-skeleton">
                  <img alt="Placeholder" />
                </figure>

                </div>
                <div class="card-body" >
               <div className="skeleton-lines">
                <div></div>
                <div></div>
                </div>
                    
                    </div>

                <div class="card-footer center">
                <div class="buttons">
                  <button class="button is-skeleton">Button Button</button>
                
                </div>

                </div>

            </div></>)

          })}</>)
          
          


        }else{
          
           return(<>


              { currentPosts.map((currentPost) => {
               
               if(!currentPost.imagen||currentPost.imagen===process.env.REACT_APP_NOMEDIA){
               //if(!currentPost.imagen||currentPost.imagen==="https://postgre-mbrd.onrender.com/media/%20"){
                 imgprdu=imgprdi;
               } else {
                 imgprdu=currentPost.imagen;

               }

               return(
               
                   
                

                 <div class="cell  card" >
                 <div class="card-image">
                   <figure class="image is-4by3 hover-zoom">
                   
                         <img class="card-img-top" src={imgprdu} alt="Card image cap" />
                   
                   </figure>
                 </div>
                 <div class="card-body" style={{padding:`0`}}>
                  
                 <Link to={`/productoDetail`} className="card-title" state={{ code: currentPost.codigo,categoria: currentPost.categoria,departamento: currentPost.departamento, header: currentPost.descripcion,price:currentPost.solicitar_precio,rating:(2+(Math.random()*(5-3))) }}>
                       <p class="title is-5" style={{color:`#0A0B0B`}}>{currentPost.descripcion+": "}</p>
                       <p class="subtitle is-5"><strong style={{color:`#555555`}}>{"RD$"+currentPost.solicitar_precio}</strong></p>
                       </Link>
                       
                       </div>
                   
                   <div class="card-footer">
                   
                   <button class="button is-link desk" style={{backgroundColor:`#107EC1`}}  value={currentPost} onClick={(e)=>AddToCart(currentPost)}>Agregar al carrito</button>
                   <button class="button is-link mob" style={{backgroundColor:`#107EC1`}}  value={currentPost} onClick={(e)=>AddToCart(currentPost)}><i class="fa fa-plus" aria-hidden="true"></i> <Icon path={mdiCartVariant}  size={1} color={"white"} /></button>

                 </div>
   
                 
                   
                 
               </div>


           )
         }
         )}
         
       
         
         
         </>)
           
       }
     


    

                  
                 
              
          
        
     };

     const serchBarr = () => {

      const serchh =()=>{
        console.log("Enter pressed, value: "+serchhValue);
        let srchText=serchhValue.toUpperCase();

        prodDataToFilter.map((p)=> {
        
          if(p.descripcion.includes(srchText)){
            console.log(p.descripcion);
            srcedProducts.push(p);
            
            
            
            
          }}
        
        )

        setProducts(srcedProducts);

      }

      return(
      <div className='field' style={{margin:`0`}}>
        <p class="control has-icons-left has-icons-right">
        <input key={products} value={serchhValue} onChange={(e)=>setSerchhValue(e.target.value)} onKeyDown={(e) => {
        if (e.key === "Enter")
            serchh();
        }}  className="input" type='search' list='data3' placeholder="Búsqueda Mundo Barato"  /> 
        <span class="icon is-small is-right"> <i class="fas fa-search"></i></span>
        <span class="icon is-small is-left"><i class="fas fa-search"></i></span>
        </p>
                                          
                                          
                                          
      </div>
      )
     }
     const dataa3=()=>{
     
    
        products.map((a, index) => {return(
         <option key={index} value={a.descripcion}>{a.descripcion}</option>)
      })
      
     }
     
     

    

    return(<>
       
       
  {serchBarr()}
  <div class="fixed-grid has-2-cols-mobile has-4-cols-tablet has-5-cols-desktop has-6-cols-widescreen" style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
  <div class="card-body grid is-col-min-8" style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}}>
   

  
           
            
               
              <PaginateOne  style={{backgroundColor:`none`,backgroundColor:"#FFFFFF55"}} />


          
                
             
           
        </div></div>
        <Paginate
                      postsPerPage={postsPerPage}
                      totalPosts={products.length}
                      paginate={paginate}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      currentPage={currentPage}
                   />



        
<ToastContainer/>



        </>
    )
}

export default MainProductList;