import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { imgCountArr } from "../FunctionalComponents/ControlComponents/ImgUploader";
//import { imgCountArr } from "../Components/FuncCompnonents/ImgUpload";
//import { imgCountsArr} from "../Components/FuncCompnonents/ImgUploadS";
//import { formEnviado } from "./Axxios";
//import { item } from "../Views/SupSucursales";

export let sumImg=0;
let imgPostCount="";


function AxiosPreSaleMain (a, b){
  
  sumImg= imgCountArr;
 
  toast.loading(`Notificando compra, por favor espere...`, { toastId: "a",
    draggablePercent: 20, type: "success", autoClose: 1000,});
  
 
    axios.post(a, b,  {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log("enviado");
        
        /*
        imgPostCount++;
       
        
        if((sumImg)===imgPostCount){
          
          toast.update("a", { render: `Preventa consolidada`, type: "success", isLoading: false });
          setTimeout(function() {
            toast.dismiss("a");
          
            window.localStorage.clear();
           window.location.reload(false);
            
         }, 1500);
        }
         */
       
           // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
           
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
                toast(`Error de conexión`, {
                    draggablePercent: 20, type: "error", autoClose: 10000, });
            }else {

            toast(`Error: ${error.message}`, {
                draggablePercent: 20, type: "error", autoClose: 10000, });}
          })   
        return(
            <ToastContainer draggablePercent={20}   limit={1} className="toast-position"/>

        );
   

}

export default AxiosPreSaleMain;