import Icon from "@mdi/react";
import { mdiCloudUpload } from "@mdi/js";
import { useEffect, useState } from "react";
import AxiosImgExactGet from "../../APIs/AxiosImgExactGet";

export let img1 = "";
export let img2 = "";
export let img3 = "";
export let img4 = "";
export let img5 = "";
export let img6 = "";
export let img7 = "";
export let img8 = "";
export let img9 = "";
export let imgList1 = [];
export let aImg1 = [];
export let aImg2 = [];
export let aImg3 = [];
export let aImg4 = [];
export let aImg5 = [];
export let aImg6 = [];
export let aImg7 = [];
export let aImg8 = [];
export let aImg9 = [];

export let imgCount = [
  img1.length,
  img2.length,
  img3.length,
  img4.length,
  img5.length,
  img6.length,
  img7.length,
  img8.length,
  img9.length,
];
export let imgCountArr = 0;

export let versioni="";
export let vDescriptioni="";
export let prdi="";

function ImgUploader(prop) {
  const [images1, setImages1] = useState([]);
  const [imageURLs1, setImageURLs1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [imageURLs2, setImageURLs2] = useState([]);
  const [images3, setImages3] = useState([]);
  const [imageURLs3, setImageURLs3] = useState([]);
  const [images4, setImages4] = useState([]);
  const [imageURLs4, setImageURLs4] = useState([]);
  const [images5, setImages5] = useState([]);
  const [imageURLs5, setImageURLs5] = useState([]);
  const [images6, setImages6] = useState([]);
  const [imageURLs6, setImageURLs6] = useState([]);
  const [images7, setImages7] = useState([]);
  const [imageURLs7, setImageURLs7] = useState([]);
  const [images8, setImages8] = useState([]);
  const [imageURLs8, setImageURLs8] = useState([]);
  const [images9, setImages9] = useState([]);
  const [imageURLs9, setImageURLs9] = useState([]);

  const [pDescripcion, setPDescripcion] = useState("");
  const [pCodigo, setPCodigo] = useState("");
  const [version, setVersion] = useState("");
  const [vDescription, setVDescripcion] = useState("");

 
  
  let iniRendertxt=0;

  if (iniRendertxt === 0) {
    if (!localStorage.getItem('prd') || localStorage.getItem('prd') == null) {} else {
        prdi = JSON.parse(localStorage.getItem('prd'));
        iniRendertxt = 1;

    }
}
const IPImgExactGet= process.env.REACT_APP_APIIMG2_URL;



useEffect(() => {
  console.log("cki changed to: ");
 
  if(!prdi||prdi===""){
    console.log("there is no prdi")
  } else{
    AxiosImgExactGet(`${IPImgExactGet}${prdi.codigo}/`);
    console.log("there is prdi "+prdi.descripcion)
    setPDescripcion(prdi.descripcion);
    setPCodigo(prdi.codigo);
    
  }
 
}, [prdi]);


  useEffect(() => {
    if (images1.length < 1) return;
    const newImageUrls1 = [];
    images1.forEach((image) => newImageUrls1.push(URL.createObjectURL(image)));
    setImageURLs1(newImageUrls1);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images1]);

  useEffect(() => {
    if (images2.length < 1) return;
    const newImageUrls2 = [];
    images2.forEach((image) => newImageUrls2.push(URL.createObjectURL(image)));
    setImageURLs2(newImageUrls2);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images2]);

  useEffect(() => {
    if (images3.length < 1) return;
    const newImageUrls3 = [];
    images3.forEach((image) => newImageUrls3.push(URL.createObjectURL(image)));
    setImageURLs3(newImageUrls3);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images3]);

  useEffect(() => {
    if (images4.length < 1) return;
    const newImageUrls4 = [];
    images4.forEach((image) => newImageUrls4.push(URL.createObjectURL(image)));
    setImageURLs4(newImageUrls4);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images4]);

  useEffect(() => {
    if (images5.length < 1) return;
    const newImageUrls5 = [];
    images5.forEach((image) => newImageUrls5.push(URL.createObjectURL(image)));
    setImageURLs5(newImageUrls5);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images5]);

  useEffect(() => {
    if (images6.length < 1) return;
    const newImageUrls6 = [];
    images6.forEach((image) => newImageUrls6.push(URL.createObjectURL(image)));
    setImageURLs6(newImageUrls6);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images6]);

  useEffect(() => {
    if (images7.length < 1) return;
    const newImageUrls7 = [];
    images7.forEach((image) => newImageUrls7.push(URL.createObjectURL(image)));
    setImageURLs7(newImageUrls7);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images7]);

  useEffect(() => {
    if (images8.length < 1) return;
    const newImageUrls8 = [];
    images8.forEach((image) => newImageUrls8.push(URL.createObjectURL(image)));
    setImageURLs8(newImageUrls8);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images8]);

  useEffect(() => {
    if (images9.length < 1) return;
    const newImageUrls9 = [];
    images9.forEach((image) => newImageUrls9.push(URL.createObjectURL(image)));
    setImageURLs9(newImageUrls9);
    imgCount = [
      img1.length,
      img2.length,
      img3.length,
      img4.length,
      img5.length,
      img6.length,
      img7.length,
      img8.length,
      img9.length,
    ];
    imgCountArr = imgCount.reduce(getSum, 0);
    console.log(imgCountArr);
  }, [images9]);

  function getSum(total, num) {
    return total + Math.round(num);
  }

  const handleChange = (e) => {
    switch (prop) {
      case "1":
        img1 = e.target.files;
        aImg1.push(img1);
        setImages1([...e.target.files]);

        break;
      case "2":
        img2 = e.target.files;
        aImg2.push(img2);
        setImages2([...e.target.files]);

        break;
      case "3":
        img3 = e.target.files;
        aImg3.push(img3);
        setImages3([...e.target.files]);
        break;
      case "4":
        img4 = e.target.files;
        aImg4.push(img4);
        setImages4([...e.target.files]);
        break;
      case "5":
        img5 = e.target.files;
        aImg5.push(img5);
        setImages5([...e.target.files]);
        break;
      case "6":
        img6 = e.target.files;
        aImg6.push(img6);
        setImages6([...e.target.files]);
        break;
      case "7":
        img7 = e.target.files;
        aImg7.push(img7);
        setImages7([...e.target.files]);
        break;
      case "8":
        img8 = e.target.files;
        aImg8.push(img8);
        setImages8([...e.target.files]);
        break;
      case "9":
        img9 = e.target.files;
        aImg9.push(img8);
        setImages9([...e.target.files]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <article class="panel">
  <p class="panel-heading" style={{backgroundColor:`#003a5d`, color:`#FFF`}} >Carga de imágenes</p>
  
  <div class="panel-block">
    <p class="control has-icons-left">
      <input class="input is-info" type="text" placeholder="Search" />
      <span class="icon is-left">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  </div>
  <a class="panel-block is-active">
    <span class="panel-icon">
    <i className="fas fa-pen"></i>
    </span>
    <input
  class="input is-info"
  type="text"
  value={prdi.descripcion}
  placeholder="Producto"
/>
  </a>
  <div className="field is-horizontal">
  <a class="panel-block is-active">
    <span class="panel-icon">
    <i className="fas fa-hashtag"></i>
    </span>
    <input
  class="input is-info"
  type="text"
  value={prdi.codigo}
  placeholder="Código"
/>
  </a>
  <a class="panel-block">
    <span class="panel-icon">
    <i className="fas fa-hashtag"></i>
    </span>
    <input
  class="input is-info"
  type="text"
  value={version}
  onChange={(e)=>{setVersion(e.target.value);versioni=e.target.value}}
  placeholder="Version"
/>
  </a>
  <a class="panel-block">
    <span class="panel-icon">
    <i className="fas fa-hashtag"></i>
    </span>
    <input
  class="input is-info"
  type="text"
  onChange={(e)=>{setVDescripcion(e.target.value);vDescriptioni=e.target.value}}
  value={vDescription}
  placeholder="Descripción de versión"
/>
  </a>
  </div>
  <div className="center">
  <a class="panel-block is-info">
   
    




    <div className="columns is-mobile is-multiline is-gapless is-centered">
        {imageURLs1.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs2.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs3.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs4.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs5.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs6.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs7.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
        {imageURLs8.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
         {imageURLs9.map((imageSrc) => (
          <div className="column is-narrow">
            {" "}
            <figure class="image is-32x32">
              <img src={imageSrc} alt="" />
            </figure>{" "}
          </div>
        ))}
      </div>


  </a></div>
</article>



      <div class="file is-centered">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            multiple
            accept="image/*"
            onChange={handleChange}
          />
          <span class="file-cta">
            <span class="file-icon">
              <Icon path={mdiCloudUpload} size={1} />
            </span>
            <span class="file-label">Cargar imagen...</span>
          </span>
        </label>
      </div>
      <br />
    </>
  );
}

export default ImgUploader;
