import React from 'react';
 
const Paginate = ({ postsPerPage, totalPosts, paginate, previousPage, nextPage,currentPage }) => {
    const pageNumbers = [];
    let currenti="";
  
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
       pageNumbers.push(i);
    }
    
    return (
       <nav class="pagination is-centered" role="navigation" aria-label="pagination">
       <a  onClick={previousPage}  class="pagination-previous">{`<`}</a>
       <a  onClick={nextPage} class="pagination-next">{`>`}</a>
       <ul class="pagination-list">
       {pageNumbers.map((number) =>{
                     if(number===currentPage){
                           currenti="is-current";
                     }else{
                        currenti="";
                     }
               return (
                  <a href={`#${number}`}>
                <li
                
                   key={number}
                   onClick={() => paginate(number)}
                   className={`pagination-link ${currenti}`}
                >
                   {number}
                </li>
                </a>

             )
       }
            )}
       </ul>
     </nav>
     
    );
 };
  
 export default Paginate;