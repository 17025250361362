import axiosMainProductGet, { allProd } from "../../APIs/AxiosMainProductGet";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import imgIcon from "../../Assets/imageIcon.svg";
import Icon from "@mdi/react";
import { mdilImage } from '@mdi/light-js';
import { blue } from "@mui/material/colors";





function PreventaList (props){
    const IPPostPreVen=process.env.REACT_APP_PREVEN;

    const [products, setProducts] = useState([]);
    const [viewOne, setViewOne] =useState(0);
    const [key1, setKey1]= useState("");
    const [key2, setKey2]= useState("");
    
    

     
    const allprodAxios=()=>{
      axiosMainProductGet(IPPostPreVen);
    }
    
     


    useEffect(() => {
      let mounted = true;

      
      

      axios.get(IPPostPreVen).then(res => {
        if(mounted) {
          
       setProducts(res.data);
      
       
       console.log("data montada "+res.data.length);
        }
            
        
        }).catch(error => {            
            console.log(`Error: ${error.message}`)
            if(error.message==="Network Error"){
               
            }
          }) 
          return () => mounted = false;

      
        
      
    }, [props.filter1,props.data1]); 

 
 
     


 




    


const prodss = () =>{
  
  let countProd=0;
  let imgRandomCount=0;
  

        if(!products[0]||!products[1]){
          
         {axiosMainProductGet(IPPostPreVen); console.log("???");}  

        }else{

       
        return(

          products.map((prod)=>{
            imgRandomCount++;
  
                return(
           <tr>
            <td>{prod.codigo}</td>
            <td>{prod.codigo_preventa}</td>
            <td>{prod.fecha}</td>
            <td>{prod.hora}</td>
            <td>{prod.metodo_pago}</td>
            <td>{prod.codigo_usuario}</td>
            <td>{prod.nombre_cliente}</td>
            <td>{prod.telefono_cliente}</td>
            <td>{prod.porciento_descuento}</td>
            <td>{prod.impuesto}</td>
            <td>{prod.total}</td>
            <td>{prod.estatus}</td>
            <td>Enlace a detalle</td>
            <td><Icon color={blue} path={imgIcon} size={1} /></td>
                      
                      </tr>)
               
              
                  console.log("after return: "+prod.descripcion);
          })
          )

        
        
      
      }

      }

    

    return(<>
       
            
              {prodss()}
                
        

        
<ToastContainer/>



        </>
    )
}

export default PreventaList;