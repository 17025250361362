import { FormControlLabel } from "@mui/material";
import { useState } from "react";

import Icon from '@mdi/react';
import { mdilUnfoldMoreHorizontal } from '@mdi/light-js';



const Collapss = ({ collapsed, children, name, iicon, func4 }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
   
  
    return (
      <>
           <div onClick={() => {setIsCollapsed(!isCollapsed); }}>
          {name}
          </div>
       
        <div
          className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
          aria-expanded={isCollapsed}
        >
           <div  style={{marginLeft:`0.5rem`, padding:`0`}}>
          {children}
          </div>
        </div>
        
        
        
      </>
    );
  };

  export default Collapss;