import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Checkbox, InputLabel, MenuItem, Rating, Select } from "@mui/material";
import camera1 from '../Assets/camera.jpg'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from "axios";
import { navBar1 } from "../Views/LandingOne";
import { ToastContainer, toast } from "react-toastify";
import NavBarOne from "./NavbarOne";
import FooterOne from "./FooterOne";



function ProductDetail (props){
    const location = useLocation();
    const { header } = location.state;
    const { price } = location.state;
    const { rating } = location.state;
    const {code} = location.state;
    const {categoria} = location.state;
    const {departamento} = location.state;

    const [images, setImages] = useState([]);
    const [comments, setComments] = useState([]);
    const [ordenSt, setOrdenSt] = useState(1);
    const [comentarioi, setComentario] = useState("");
    
    let valueRating=3.5;
    let ordenn=1;
    let comment1;
    let poseeProductoi=false;
    
    
    const IPimgGet=  process.env.REACT_APP_APIIMG2_URL+code+"/";
    const IPCommentGet=  process.env.REACT_APP_COMMENTPRD_URL;
    const IPrawPage= process.env.REACT_APP_RAWPAGE;


    useEffect(() => {
        let mounted = true;
  
        
        
  
        axios.get(IPimgGet).then(res => {
          if(mounted) {
            {window.scrollTo(0,0)}
         setImages(res.data);
         console.log("res data: "+res.data);
         
         
          }
              
          
          }).catch(error => {            
              console.log(`Error: ${error.message}`)
              if(error.message==="Network Error"){
                 
              }
            }) 
            return () => mounted = false;

          
          
        
      }, [code]); 

      useEffect(() => {
        let mounted2 = true;
  
        
        
  
        axios.get(IPCommentGet).then(res => {
          if(mounted2) {
            
            setComments(res.data);
            console.log("res data: "+res.data);
         
         
          }
              
          
          }).catch(error => {            
              console.log(`Error: ${error.message}`)
              if(error.message==="Network Error"){
                 
              }
            }) 
            return () => mounted2 = false;

          
          
        
      }, [code]); 




      useEffect(() => {
       
    if(!comment1){}else{
        axios.post(IPCommentGet, {  
          
          codigoProducto: code,
          comentario: comment1,
          usuario: "PruebaUser",
          fecha: "02/08/2024",
          poseeProducto: poseeProductoi,
          RatingProducto: 3,
          RatingAtencion: 4
        },  {
          headers: {
            'Content-Type': 'application/json'
          }}).then(res => {
           
           
            
           
              
              toast.update("a", { render: `Comentario almacenado`, type: "success", isLoading: false });
              setTimeout(function() {
                toast.dismiss("a");
              
                //window.localStorage.clear();
               window.location.reload(false);
                
             }, 1500);}
            ).catch(error => {            
              console.log(`Error: ${error.message}`)
              if(error.message==="Network Error"){
                  toast(`Error de conexión`, {
                      draggablePercent: 20, type: "error", autoClose: 10000, });
              }else {
  
              toast(`Error: ${error.message}`, {
                  draggablePercent: 20, type: "error", autoClose: 10000, });}
            })   
          return(
              <ToastContainer draggablePercent={20}   limit={1} className="toast-position"/>
  
          );
       
 } }, [comment1]);

        const sendComment = () => {
          let currentDate = new Date();
          let fechaTime = (currentDate.getDate())+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+"_"+currentDate.getHours()+":"+currentDate.getMinutes();
          console.log(fechaTime);
        // imgID = [pickDatei, Usuario, SucName];
      
        axios.post(IPCommentGet, {  
          
          codigoProducto: code,
          comentario: comentarioi,
          usuario: "Usuario_Invitado",
          fecha: fechaTime,
          poseeProducto: 0,
          RatingProducto: 3,
          RatingAtencion: 4
        },  {
          headers: {
            'Content-Type': 'application/json'
          }}).then(res => {
           
           
            
           
              
              toast.update("a", { render: `Comentario almacenado`, type: "success", isLoading: false });
              setTimeout(function() {
                toast.dismiss("a");
              
                //window.localStorage.clear();
               window.location.reload(false);
                
             }, 1500);}
            ).catch(error => {            
              console.log(`Error: ${error.message}`)
              if(error.message==="Network Error"){
                  toast(`Error de conexión`, {
                      draggablePercent: 20, type: "error", autoClose: 10000, });
              }else {
  
              toast(`Error: ${error.message}`, {
                  draggablePercent: 20, type: "error", autoClose: 10000, });}
            })   ;
          
        
      };



    const imagez =(b) =>{
        
    
        return(

        images.map((imge)=>{
            //<img class="card-img-top w-100" src={images[0].photo} alt="Card image cap" style={{height:`30rem`}}/>
            let a=imge.photo;
            console.log(a);
            if(!b){
                if(imge.orden!=ordenSt){
                return(
            
                    <img onClick={()=>{setOrdenSt(imge.orden);console.log("orden change: "+ordenn)}} class="card-img-top" src={`${IPrawPage}${a}`} alt="Card image cap" style={{width:`10rem`,height:`10rem`}}/>
                    
                   
                   
                    )}


            }else{
                if(imge.orden===ordenSt){
            return(           
                
                <img onClick={()=>{setOrdenSt(imge.orden);console.log("orden change: "+ordenn); console.log("Page: "+IPrawPage)}}  class="card-img-top" src={`${IPrawPage}${a}`} alt="Card image cap" style={{width:`20rem`,height:`20rem`}}/>
                  
            )}
        }

    })

)
    }

    const Commentz =(c) =>{
        
    
      return(

      comments.map((comment)=>{
          //<img class="card-img-top w-100" src={images[0].photo} alt="Card image cap" style={{height:`30rem`}}/>
          let a=comment.comentario;
          console.log(a);
          if(comment.public!="true"){}else{
       
              return(
          
                <article class="media">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img src="https://bulma.io/assets/images/placeholders/128x128.png" />
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content is-small">
                    <p>
                      <strong>{comment.usuario}</strong> <small>@{comment.usuario}</small> <small>{comment.fecha}</small>
                      <br />
                      {comment.comentario}
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a class="level-item">
                        <span class="icon is-small" style={{color:`#4267B2`}}><i class="fas fa-reply"></i></span>
                      </a>
                      <a class="level-item">
                        <span class="icon is-small"><i class="fas fa-retweet"></i></span>
                      </a>
                      <a class="level-item">
                        <span class="icon is-small" style={{color:`#898F9C`}}><i class="fas fa-heart"></i></span>
                      </a>
                    </div>
                  </nav>
                </div>
              
              </article>
                  
                 
                 
                  )}

  })

)
  }


    return(
        <>
        
        <NavBarOne />
        <div className='level ' style={{backgroundColor:`yellow`}}>
                <div className='level-item'></div>
                    <div className="level-item marquee enable-animation">
                        <div className="marquee__content">
                
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento!</strong> 
                            <strong className=' marquee__item ' style={{color:`#000`}}>Página en mantenimiento! Página en mantenimiento! Página en mantenimiento! </strong> 
                        </div>
                    </div>
                <div className='level-item'></div>
            
            </div>
        <div class="box">
       
            <div class="row">
            <div  class="col-sm-2">
                
                
                {imagez()}
               
                </div>
                <div  class="col-sm-5">
                <br/><br/><br/>
                {imagez(ordenSt)}
                
               
                </div>
            
              
                <div class="col-sm-5">
                <br/><br/><br/>
                    <div className="box is-info">
                <h3><strong>{header}</strong></h3>
               
                
                <div class="alert alert-light" role="alert">
                <h4><strong style={{color:`black`}}>{"RD$"+price}</strong></h4>
                </div>
                <ul style={{listStyleType:`none`, padding:`0.5%`}}>
                <li>
                <button className="button is-rounded" style={{backgroundColor:`#107EC1`, color:`white`}}><strong >Añadir al carrito</strong></button>
                <li></li>
                <button className="button is-rounded is-light"  ><strong style={{color:`#FF0000`}}>Comprar ya</strong></button>
                </li>
                </ul>
                <div>
                    <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                   
                </div></div>
                </div>
            </div>
        </div>


        <div class="row">
        <div className="box col-sm-5 is-small">

            {Commentz()}

            
      <div className="container">
              <article class="media">
        <figure class="media-left">
          <p class="image is-64x64">
            <img src="https://bulma.io/assets/images/placeholders/128x128.png" />
          </p>
        </figure>
        <div class="media-content">
          <div class="field">
            <p class="control">
            <input className="textarea is-small"
                                value={comentarioi}
                                onChange={
                                    (e) => setComentario(e.target.value)
                                }
                                type="text"
                                placeholder="Comentario"></input>
            </p>
          </div>
          <nav class="level">
            <div class="level-left">
              <div class="level-item">
                <a class="button is-info" onClick={()=>{sendComment();
                console.log(comentarioi, code)}}>Submit</a>
              </div>
            </div>
          
          </nav>
        </div>
      </article>
      </div>


</div>

<div className="col-sm-5"></div>
</div>


        
        
        <br/><br/><br/>
        <FooterOne />
        <ToastContainer draggablePercent={20}   limit={1} className="toast-position"/>
        </>

    )
}

export default ProductDetail;
