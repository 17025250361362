import { useEffect, useState } from "react"
import { allCartProd } from "../FunctionalComponents/MainProductList"
import camera1 from '../Assets/camera.jpg'
import { Link, json } from "react-router-dom";
import wapplogo from '../Assets/WappLogo.png';
import logo1 from '../Assets/MundoBarato3.png';

import Icon from '@mdi/react';
import { mdiCashMultiple, mdiBank, mdiBankPlus,mdiCreditCardCheck} from '@mdi/js';
import CalcSubTotal from "../FunctionalComponents/calcSubTotal";
import AxiosPreSaleMain from "../APIs/AxiosPreSaleMain";
import { toast, ToastContainer } from "react-toastify";
import AxiosPreSaleDetail from "../APIs/AxiosPreSaleDetail";
import NavBarOne from "../FunctionalComponents/NavbarOne";
import FooterOne from "../FunctionalComponents/FooterOne";
import CartProdRender from "../FunctionalComponents/cartProdRender";
import { TapasTwoTone } from "@mui/icons-material";
import { telephoneNumber } from "node-openssl-cert/name_mappings";

function CarritoOne (props) {

  const [cartProds, SetCartProds] = useState([]);
  const [cartProdsi, SetCartProdsi] =useState([]);
  const [cantidadi, setCantidadi] =useState(["",""]);
  const [totalAmount, setTotalAmount] =useState([0]);
  const [totalAmountShow, setTotalAmountShow] = useState(0);
  const [codigoi, setCodigoi] = useState();
  const [arrayfill2, setArrayfill2] = useState([]);

  const [customerPhone, setCustomerPhone] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deliveryType, setDeliveryType] = useState("Delivery");
  const [tienda, setTienda]=useState("");

  const [productPrice, setProductPrice] = useState([]);
  
  const IPPostPreVen=process.env.REACT_APP_PREVEN;
  const IPPostPreVenDet=process.env.REACT_APP_PREVENDETAIL;

  const today = new Date();
  const month = today.getMonth()+1;
  const year = today.getFullYear();
  const date = today. getDate();
  const currentDate = date + "/" +month+ "/" + year;
  let time = today.getTime();
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;


  let currentTime=today.getHours()+":"+today.getMinutes();


  let arrayfill=[];


  
  
  

  useEffect(() => {
   //SetCartProds(allCartProd);

   if (!localStorage.getItem('allCartProdLocal') || localStorage.getItem('allCartProdLocal') == null) {} else {
    SetCartProds(JSON.parse(localStorage.getItem('allCartProdLocal')));
  }

   console.log("Cantidad de productos en carrito:"+cartProds.length);
  }, [allCartProd]); 

  useEffect(() => {
    //setTotalAmountShow(totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)));
    console.log("totalAmountShow: "+totalAmountShow);
   }, [totalAmount,cantidadi]); 

  function closeNotification() {
    props.toggle()
};

function Amounttt(props){

  return(<>
  
    {props.total}
    </>
  );

}

const CartProdDeleteItem = (prod) => {

  const prodCheck= (arr, valor ) => {
        
    return arr.some((arrVal)=> valor === arrVal);


  }

 

  cartProds.map((p)=>{
   
    if(p.codigo===prod){
      const newArray=[...cartProds];
     newArray.splice((newArray.indexOf(p)), 1);
      console.log("cartProdsNew? "+JSON.stringify(newArray));
      
      SetCartProds(newArray);
      
      localStorage.setItem('allCartProdLocal', JSON.stringify(newArray));
      
  }



})
}


const allCartProdRender = () =>{
  let cant=["1"];

  if(!cartProds[0]){
          
    return(
      <div className="label">Carrito vacío...</div>)

  }else{


    return(

  cartProds.map((prod)=>{
    cant[prod.codigo]=1;
    let a = prod.solicitar_precio.replace(/,/, '');
    let h=[];
      //console.log("inside prodMap: "+prod.codigo);
    if(!prod.cantidad){
      Object.assign(prod, {cantidad:1})
      //Object.defineProperty(prod, "cantidad", {value:"1"});
      h = parseFloat(a);
    } else{
      h = parseFloat(a)*prod.cantidad;
    }

     /*
      if(!cantidadi[prod.codigo]){
       
      }else{
        h = parseFloat(a)*cantidadi[prod.codigo];
      }
      <input className="input is-light" type="number" placeholder="1" value={cantidadi[prod.codigo]} onChange={(e)=>{cantidadi.splice(prod.codigo,0,e.target.value); h=e.target.value*a;  totalAmount[prod.codigo]=h; console.log(totalAmount)}}/>  
      
      */
      
      arrayfill.push(prod.descripcion);
      arrayfill2[prod.codigo]="["+"Codigo: "+prod.codigo+","+"Nombre: "+prod.descripcion+","+"Precio: "+a+","+"cantidad"+cantidadi[prod.codigo]+"]";
      a=parseFloat(a);
      if(!totalAmount){}else{
      totalAmount[prod.codigo]=h;}
       
        return( 
          <div class="card mb-3 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <div>
                          <img
                            src={prod.imagen}
                            class="img-fluid rounded-3" alt="Shopping item" style={{width: `65px`}}/>
                        </div>
                        <div class="ms-3">
                          <h5>{prod.descripcion}</h5>
                          <p class="small mb-0">{prod.marca}</p>
                        </div>
                      </div> 
                      <div class="d-flex flex-row align-items-center">
                        
                        <div class="control" key={cartProds}>
                        <input className="input is-light" type="number" placeholder="1"  onChange={(e)=>{prod.cantidad=e.target.value; h=e.target.value*a;productPrice[prod.codigo]=e.target.value*a;   totalAmount[prod.codigo]=h; console.log(totalAmount);  console.log("Current amount: "+productPrice[prod.codigo]);console.log("cantidad: "+prod.cantidad)}}/>
                          
                        </div>
                        <div style={{width: `100%`}} key={productPrice}>
                          <h5 class="mb-0">{"RD$"+productPrice[prod.codigo]}</h5>
                        </div>
                        <a href="#!" style={{color: `#cecece`}} onClick={()=>CartProdDeleteItem(prod.codigo)}><i class="fas fa-trash-alt"></i></a>
                      </div> 
                    </div>
                  </div>
                </div>
              
              )
      
      
         
  })
  )

}


}

const CartToMessage = () =>{
  
  arrayfill=cartProds.join(",");

  /*
  cartProds.forEach((prod)=>{
    //console.log("inside prodMap: "+prod.codigo);
    arrayfill.push(prod.descripcion+"#20");
            
                 
    
       
})*/

  return(
    arrayfill
  )
  

/*
    return(

  cartProds.forEach((prod)=>{
      //console.log("inside prodMap: "+prod.codigo);
      {prod.descripcion+"#20"}
              
                   
      
         
  })
  )
*/



}


//Para enviar cada producto al detalle
useEffect(() => {
  if (cartProdsi.length < 1) return;
  //imgIDi = imgID.join(",") + ",Q1";
  //console.log(imgIDi);
  console.log("codigoi en useEffect: "+codigoi);






  cartProds.map((prod) => {

    console.log("productos: "+ prod.descripcion+codigoi+prod.codigo+(cantidadi[prod.codigo]?cantidadi[prod.codigo]:1));
    let a = prod.solicitar_precio;

    AxiosPreSaleDetail(IPPostPreVenDet, {  
    codigo_preventa: codigoi,
    codigo_articulo: prod.codigo,
    codigo_version: 0,
    cantidad: (cantidadi[prod.codigo]?cantidadi[prod.codigo]:1),
    precio:a,
    porciento_descuento: 0.0,
    porciento_impuesto: 0.0,
    descripcion: prod.descripcion,
    total_articulo: (a*(cantidadi[prod.codigo]?cantidadi[prod.codigo]:1)),
    estatus_prod: 1
  
  });
  });
}, [cartProdsi]);

useEffect(() => {
  if(!codigoi){return};
  saveVentaOne();
 }, [codigoi]); 



const saveVentaTwo =() =>{
  
  setCodigoi("codigoUsuario"+currentDate+currentTime+"metodoDPago"+(totalAmount.reduce((a,b)=>parseFloat(a=a+b,0))));
 

}


const saveVentaOne = () =>{
  

console.log("codigoi en saveVenta"+codigoi);

SetCartProdsi(cartProds);
console.log(today+time);

//agregar el tipo de entrega pickup, delivery, envio

  AxiosPreSaleMain(IPPostPreVen, { 
    codigo:0,
    codigo_preventa: codigoi,
    fecha: currentDate,
    hora:  currentTime,
    metodo_pago: "Efectivo",
    codigo_usuario: 1,
    nombre_cliente: "Cliente de prueba",
    telefono_cliente: customerPhone,
    porciento_descuento:  0.0,    
    impuesto: "0",
    total: totalAmount.reduce((a,b)=>parseFloat(a=a+b,0)),    
    detalle_concat: arrayfill2.join(','),
    estatus: 1  
   
   
  });


}   

const CartProdLocalSto = () => {
  


}


return(
  <>
  <div style={{position:`relative`,zIndex:`2`}}>
 <NavBarOne where={"carrito"} /></div>

  <section class="h-100 h-custom desk" style={{backgroundColor: `#eee`}}>
  <div class="card py-5 h-100">
    
     
        <div class="container is-fluid">
          <div class="card-body p-4" style={{position:`relative`}}>

          <div class="columns " key={cartProds}>
              <div class="column is-8">
              <Link to={`/`}>
                <h5 class="mb-3 center"><a href="#!" class="text-body box" style={{width:`max-content`}}>
                  <i class="fas fa-long-arrow-alt-left me-2"></i>Seguir comprando</a>
                </h5>
              </Link>
                

                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p class="mb-1">Carrito de compras</p>
                    <p class="mb-0">Posee {cartProds.length} productos en su carrito</p>
                  </div>
                  <div>
                  <p class="box"><strong>Subtotal</strong> RD${totalAmountShow? totalAmountShow: totalAmount.reduce((a,b)=>parseFloat(a=a+b,0))}</p>
                  </div>
                </div>
                <div key={cartProds}>
                <CartProdRender cart={cartProds} arrayfill={arrayfill} arrayfill2={arrayfill2} cantidadi={cantidadi} totalAmount={totalAmount} setTotalAmount={setTotalAmountShow} productPrice={productPrice} CartProdDeleteItem={CartProdDeleteItem}></CartProdRender>
                
                </div>
                
                

              </div>
              <div class="column is-3">
             
                <div class="card bg-primary text-white rounded-3" style={{ position:`fixed`, marginTop:`-20%`,zIndex:`0`}}>
                <img src={logo1} width="60%" style={{marginLeft:`25%`}}/>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-4">
                      <h5 class="mb-0">Realizar compra</h5>
                    
                     
                    </div>
                    <input className="input" value={customerPhone} onChange={(e)=>{setCustomerPhone(e.target.value)}} placeholder="Número telefónico" type="text"></input>
                    <div class="fixed-grid has-1-cols">
                    <div className="grid">
                    
                    <ul class="cell" >
                    
                      <div class="control">
                        <label class="radio">
                          <input type="radio" name="DeliveryOrPickup" value="Delivery" onChange={(e)=>{setDeliveryType(e.target.value)}} />
                          Delivery
                        </label>
                        <label class="radio">
                          <input type="radio" name="DeliveryOrPickup" value="Pickup" onChange={(e)=>{setDeliveryType(e.target.value)}}  />
                          Pickup
                        </label>
                      </div>
                      
                      </ul>
                      <ul className="cell level">
                      Método de pago
                      <div class="select is-link">
                        <select onChange={(e)=>{setPaymentMethod(e.target.value)}}>
                          <option value={"Depósito"}>Depósito</option>
                          <option value={"Efectivo"}>Efectivo</option>
                          <option value={"Transferencia"}>Transferencia bancaria</option>                          
                          <option value={"TDC"}>Tarjeta de crédito</option>
                        </select>
                      </div>
                    </ul>
                    <ul className="cell level">
                    Tienda cercana
                      <div class="select is-link">
                        <select onChange={(e)=>{setTienda(e.target.value)}}>
                        <option value={""}>Seleccione</option>
                          <option value={"%20Tienda:%20Santiago"}>Santiago</option>
                          <option value={"%20Tienda:%20Santo%20Domingo"}>Santo Domingo</option>
                          <option value={"%20Tienda:%20Coral%20Mall"}>Coral Mall</option>                          
                          
                        </select>
                      </div>
                    </ul>
                   

                  
                  
                    </div>
                    
                    </div>

                    

                 
                    
                    
                  
                    <br/>
                    <a onClick={(e)=>{ if(customerPhone.length<10||!customerPhone){e.preventDefault();   toast.warning(`¡Por favor ingrese su número telefónico!`, {
      position: "top-right",
      
  
      }); }else{ if(deliveryType==="Pickup"&!tienda){e.preventDefault();   toast.warning(`¡Por favor seleccione donde va a retirar(Pickup) el pedido!`, {
        position: "top-right",
        
    
        });}else{  console.log("arrayfill Length: "+arrayfill.length);arrayfill=JSON.stringify(arrayfill2.join(""));saveVentaTwo() } }}} href={`https://wa.me/18496544656?text=Usuario:%20${customerPhone}.%20Método%20de%20pago:%20${paymentMethod}.%20Modo:%20${deliveryType}.%20${tienda}.%20%20%20Buen%20día%20deseo%20comprar%20los%20siguientes%20${cartProds.length}%20productos:%20${JSON.stringify(arrayfill2.join(""))}%20`}>   <button type="button" class="btn btn-info btn-block btn-lg">
                      <div class="d-flex justify-content-between" >
                        <span>RD${totalAmountShow? totalAmountShow: totalAmount.reduce((a,b)=>parseFloat(a=a+b,0))}</span>
                        <span>COMPRAR <img className='d-inline' src={wapplogo} alt='...' /> <i class="fas fa-long-arrow-alt-right ms-2"></i></span>
                      </div>
                    </button></a>

                  </div>
                </div>

              </div>


          </div>
        </div>
      </div>
    </div>
  
</section>


<section class="h-100 h-custom mob" style={{backgroundColor: `#eee`}}>
  <div class="card py-5 h-100">
    
     
        <div class="container is-fluid">
          <div class="card-body p-4" style={{position:`relative`}}>

          
             
              <Link to={`/`}>
                <h5 class="mb-3 center"><a href="#!" class="text-body box" style={{width:`max-content`}}>
                  <i class="fas fa-long-arrow-alt-left me-2"></i>Seguir comprando</a>
                </h5>
              </Link>
                

                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p class="mb-1">Carrito de compras</p>
                    <p class="mb-0">Posee {cartProds.length} productos en su carrito</p>
                  </div>
                  <div>
                  <p class="box"><strong>Subtotal</strong> RD${totalAmountShow? totalAmountShow: totalAmount.reduce((a,b)=>parseFloat(a=a+b,0))}</p>
                  </div>
                </div>
                <div key={cartProds}>
                <CartProdRender cart={cartProds} arrayfill={arrayfill} arrayfill2={arrayfill2} cantidadi={cantidadi} totalAmount={totalAmount} setTotalAmount={setTotalAmountShow} productPrice={productPrice} CartProdDeleteItem={CartProdDeleteItem}></CartProdRender>
                
                </div>
                
                

         
             
                <div class="card bg-primary text-white rounded-3" style={{ position:`relative`,zIndex:`0`}}>
                
                  <div class="card-body" >
                    <div class="d-flex justify-content-between align-items-center mb-4">
                      <h5 class="mb-0">Realizar compra RD${totalAmountShow? totalAmountShow: totalAmount.reduce((a,b)=>parseFloat(a=a+b,0))}</h5>
                    
                     
                    </div>
                    <input className="input" value={customerPhone} onChange={(e)=>{setCustomerPhone(e.target.value)}} placeholder="Número telefónico" type="number"></input>
                    <div>
                    <div className="grid">
                    
                    <ul class="cell" >
                    
                      <div class="control">
                        <label class="radio">
                          <input type="radio" name="DeliveryOrPickup" value="Delivery" onChange={(e)=>{setDeliveryType(e.target.value)}} />
                          Delivery
                        </label>
                        <label class="radio">
                          <input type="radio" name="DeliveryOrPickup" value="Pickup" onChange={(e)=>{setDeliveryType(e.target.value)}}  />
                          Pickup
                        </label>
                      </div>
                      
                      </ul>
                      <ul className="cell ">
                      Método de pago
                      <div class="select is-link">
                        <select onChange={(e)=>{setPaymentMethod(e.target.value)}}>
                          <option value={"Depósito"}>Depósito</option>
                          <option value={"Efectivo"}>Efectivo</option>
                          <option value={"Transferencia"}>Transferencia bancaria</option>                          
                          <option value={"TDC"}>Tarjeta de crédito</option>
                        </select>
                      </div>
                    </ul>
                    <ul className="cell ">
                    Tienda cercana
                      <div class="select is-link">
                        <select onChange={(e)=>{setTienda(e.target.value)}}>
                        <option value={""}>Seleccione</option>
                          <option value={"%20Tienda:%20Santiago"}>Santiago</option>
                          <option value={"%20Tienda:%20Santo%20Domingo"}>Santo Domingo</option>
                          <option value={"%20Tienda:%20Coral%20Mall"}>Coral Mall</option>                          
                          
                        </select>
                      </div>
                    </ul>
                   

                  
                  
                    </div>
                    
                    </div>

                    

                 
                    
                    
                  
                    <br/>
                    <a onClick={(e)=>{ if(customerPhone.length<10||!customerPhone){e.preventDefault();   toast.warning(`¡Por favor ingrese su número telefónico!`, {
      position: "top-right",
      
  
      }); }else{ if(deliveryType==="Pickup"&!tienda){e.preventDefault();   toast.warning(`¡Por favor seleccione donde va a retirar(Pickup) el pedido!`, {
        position: "top-right",
        
    
        });}else{  console.log("arrayfill Length: "+arrayfill.length);arrayfill=JSON.stringify(arrayfill2.join(""));saveVentaTwo() } }}} href={`https://wa.me/18496544656?text=Usuario:%20${customerPhone}.%20Método%20de%20pago:%20${paymentMethod}.%20Modo:%20${deliveryType}.%20${tienda}.%20%20%20Buen%20día%20deseo%20comprar%20los%20siguientes%20${cartProds.length}%20productos:%20${JSON.stringify(arrayfill2.join(""))}%20`}>   <button type="button" class="btn btn-info btn-block btn-lg">
                      <div class="d-flex justify-content-between" >
                       
                        <span> COMPRAR<img className='d-inline' src={wapplogo} alt='...' /> </span>
                      </div>
                    </button></a>

                  </div>
                </div>

            


        
        </div>
      </div>
    </div>
  
</section>

<ToastContainer/>

</>
)


}

export default CarritoOne;