
import './App.css';
import ProductDetail from './FunctionalComponents/ProductDetail';
import CarritoOne from './Views/CarritoOne';
import Controli from './Views/Control';
import ErrorPage from './Views/Error-Page';
import LandingOne from './Views/LandingOne';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import QuienesSomos from './Views/QuienesSomos';
import Politicas from './Views/Politicas';



function App() {


  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingOne />,
      errorElement: <ErrorPage />,
      
   

    },
    {
      path: "/carrito",
      element: <CarritoOne />,
    },
    {
      path: "/productoDetail",
      element: <ProductDetail />,
    },
    {
      path: "/control",
      element: <Controli />,
    },
    {
      path: "/quienesSomos",
      element: <QuienesSomos />,
    },
    {
      path: "/politicas",
      element: <Politicas />,
    },
    
  ]);


  return (
    <div className="App">
    <RouterProvider router={router} />
          
    </div>
  );
}

export default App;
